import React from 'react'
import { Flex } from 'grid-styled';
import FlexContainer from '@components/common/flex-container'
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import { updateActivePageIndex } from '@redux/actions'

import SampleCards from '@data/samplecards'
import { AutoSizedImage } from '@components/common/image'

import Pagination from '@components/pagination'
import Card from './components/card'


const BrowserContainer = styled(({ ...props}) => <Flex {...props} />)`
  cursor: ${({ cursor }) => (cursor)};
  pointer-events: ${({ pointer }) => (pointer) ? pointer : ""};
  top: ${({ top }) => (top) ? top : ""};
  left: ${({ left }) => (left) ? left : ""};
  position: ${({ position }) => (position) ? position : ""};
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};
  z-index: ${({ zindex }) => (zindex) ? zindex : ""};
  ${'' /* border: 1px solid white; */}
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : ""};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : ""};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  margin-left: ${({ marginleft }) => (marginleft) ? marginleft + "px" : ""};
  margin-right: ${({ marginright }) => (marginright) ? marginright + "px" : ""};
  padding-top: ${({ paddingtop }) => (paddingtop) ? paddingtop + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  border: ${({ border }) => (border)};
  border-radius: ${({ radius }) => (radius)};
  overflow-x: ${({ overflowx }) => (overflowx) ? overflowx : ""};
  overflow-y: ${({ overflowy }) => (overflowy) ? overflowy : ""};

  scroll-snap-align: ${({ scrollsnapalign }) => (scrollsnapalign) ? scrollsnapalign : ""};
  scroll-snap-type: ${({ scrollsnaptype }) => (scrollsnaptype) ? scrollsnaptype : ""};

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;

  scroll-behavior: smooth;


    /* ::-webkit-scrollbar {
      display: none;
    }


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */ */

`

class CardBrowser extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        animationActive: false,
        activeIndex: 0,
        offset: 0,
        animatedOffset: 0,
        direction: "N/A",
        dimensions: [
          {
            breakpoint:0,
            width: 90,
            height: 90
          },
          {
            breakpoint:1,
            width: 90,
            height: 90
          },
          {
            breakpoint:2,
            width: 90,
            height: 90
          },
        ]
      }

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)
      this.animateToNextCard = this.animateToNextCard.bind(this)

      // Scroll Events
      this.eventsAdded = false

      this.indexAtStartOfScroll = -1
      this.initialScroll = -1
      this.currentScroll = 0
      this.addition = 0

      // For Logs
      this.devMode = true
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)
      // window.requestAnimationFrame(this.requestAnimationFrame);
      // this.refreshScrollListener()
    }

    componentWillUnmount() {
    }

    componentDidUpdate(newProps){

    }

    // MARK : UI Dataset
    setDataPoints() {
      this.paginationBarHeight = 60
      this.margintop = this.props.breakpoint <= 1 ? 0 : 116

      this.height = this.props.breakpoint <= 1 ? (window.innerHeight) : (window.innerHeight-this.margintop-this.paginationBarHeight)
      this.width = window.innerWidth

      this.count = SampleCards.length // Four at a time

      switch (this.props.breakpoint) {
        case 0:
          this.cardWidth = this.width*0.8
          this.cardHeight = this.height*0.8
          break
        case 1:
          this.cardWidth = this.width*0.8-this.props.margin*2
          this.cardHeight = this.height*0.8-this.props.margin*2
          break
        case 2:

          const widthScale = 1
          const fraction = 0.7
          const aspectRatio = 640/866

          this.cardWidth = this.width*widthScale*fraction
          this.cardHeight = this.cardWidth*aspectRatio
      }

      this.scrollableDistancePerIndex = this.props.breakpoint > 1 ?
                               this.cardWidth + this.props.margin :
                               this.cardWidth;

      this.totalBrowserSize = this.props.breakpoint > 1 ?
                               (this.width-this.cardWidth) + (this.cardWidth+this.props.margin) * this.count :
                               this.cardWidth*this.count;


    }

    onResize(){
      // this.forceUpdate()
    }

    onRelease(index, offset, direction) {
      // console.log("Release index : ", index);
      // console.log("Release offset : ", offset);
      // console.log("Release direction : ", direction);

      this.setState({
        animationActive: true,
        isPressed: index,
        offset: offset,
        direction: direction
      })

      requestAnimationFrame(this.animateToNextCard);
    }


    renderCards(width, height) {

      var cards = []
      var top = 0
      var left = 0
      var red = "red"

      const { activeIndex, animationActive, isPressed, offset, animatedOffset, direction } = this.state

      for ( var i = 0; i < 3; i++ ) {
        var currentIndex = i + activeIndex

        if (currentIndex > this.count-1) {
          currentIndex -= (this.count)
        }

        const spacing = 20

        const currentCard = SampleCards[currentIndex]
        top = (this.height-this.cardHeight)/2 + spacing*i
        left = (this.width-this.cardWidth)/2 + 10-spacing * i



        if (animationActive) {
          if (isPressed === currentIndex) {
            left = left + offset + animatedOffset
          } else {
            switch (direction) {
              case "left":
              top = (this.height-this.cardHeight)/2 + spacing*i + animatedOffset/this.cardWidth * spacing
              left = left - animatedOffset/this.cardWidth * spacing
              break
              default:
              top = (this.height-this.cardHeight)/2 + spacing*i - animatedOffset/this.cardWidth * spacing
              left = left + animatedOffset/this.cardWidth * spacing
            }

          }
        }

        if (this.props.colors !== undefined) {
          red = this.props.colors.red
        }


        cards.push(<Card
          key={"card-"+i}
          index={currentIndex}
          activeIndex={this.state.activeIndex}
          zindex={this.count-i}
          top={top}
          left={left}
          width={this.cardWidth+"px"}
          height={this.cardHeight+"px"}
          backgroundcolor={"rgba(255, 255, 255, "+(1-i*(1/this.count))+")"}
          image={ this.props.breakpoint > 1 ? currentCard.image.desktop : currentCard.image.mobile }
          onRelease={(i, o, d) => this.onRelease(i, o, d)}
        />)
      }
      // return cards
      return (
        <BrowserContainer
          id="card-carrousel-container"
          position={"relative"}
          width={"100vw"}
          height={this.height + "px"}
          backgroundcolor={red}
          margintop={this.margintop}
          overflowy="hidden"
          overflowx="hidden"
        >
          { cards}
        </BrowserContainer>)

      //
    }

    // ANIMATION

    animateToNextCard() {

      const animationFactor = 20

      if (Math.abs(this.state.animatedOffset) < (this.cardWidth)) {
        // console.log("AnimateTo Next Card!");
        if (this.state.direction === "left") {
          // console.log("LEFT");
            this.setState({
              animatedOffset: this.state.animatedOffset - animationFactor
            })
        } else {
          // console.log("RIGHT");
          this.setState({
            animatedOffset: this.state.animatedOffset + animationFactor
          })
        }

        requestAnimationFrame(this.animateToNextCard)
      } else {
        // console.log("Complete");

        var newIndex = this.state.activeIndex + 1

        if (newIndex > this.count-1) {
          newIndex = 0
        }

        this.setState({
          activeIndex: newIndex,
          animatedOffset: 0,
          offset: 0,
          animationActive: false,
          isPressed: -1
        })
      }

    }

    // RENDER

    render(){
      if (this.props.margin === undefined) {
        return <div/>
      }
      this.setDataPoints()


      return(
        <div>
        {this.renderCards()}
        </div>
      )
    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateActivePageIndex: updateActivePageIndex
  }, dispatch);
}

function mapStateToProps(state){
  return{
    colors: state.styleguide.colors,// XXX:
    activePageIndex: state.activePageIndex,
    breakpoint: state.gridData.breakpoint,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    margin: state.gridData.margin
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CardBrowser);
