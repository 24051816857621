const header = "Helvetica"
const subheader = "Helvetica"
const sectionTitle = "Helvetica"
const sectionSubTitle = "Helvetica"
const body = "Helvetica"


const navItemMobile = "Sanomat Light"
const navItemDesktop = "Right Grotesk Wide Regular"

export default {
  colors: {
    red: "rgba(236,28,36, 1)",
    redshadow: "rgba(236,28,36, 0.6)",
    black: "black",
    paginationOffBlack: "rgba(0,0,0, 0.5)",
    blackshadow: "rgba(0,0,0,0.8)",
    white: "white",
    paginationOffWhite: "rgba(255,255,255,0.5)"
  },


  // PasswordProtectionScreen

  passwordProtectionScreen: {
    fontSet: [
      {
        breakpoint: 0,
        portrait: {
          font: "Helvetica",
          fontSize: 14,
          fontWeight: 300,
          lineheight: 1.6
        },
        landscape: {
          font: "Helvetica",
          fontSize: 14,
          fontWeight: 300,
          lineheight: 1.6
        },
      },
      {
        breakpoint: 1,
        portrait: {
          font: "Helvetica",
          fontSize: 14,
          fontWeight: 300,
          lineheight: 1.6
        },
        landscape: {
          font: "Helvetica",
          fontSize: 14,
          fontWeight: 300,
          lineheight: 1.6
        },
      },
      {
        breakpoint: 2,
        portrait: {
          font: "Helvetica",
          fontSize: 18,
          fontWeight: 300,
          lineheight: 1.6
        },
        landscape: {
          desktop:{
            font: "Helvetica",
            fontSize: 18,
            fontWeight: 300,
            lineheight: 1.6
          },
          tablet:{
            font: "Helvetica",
            fontSize: 18,
            fontWeight: 300,
            lineheight: 1.6
          }
        },
      }
    ]
  },

  // Emerson Styleguide

  navItem: {
    fontSet: [
      {
        breakpoint: 0,
        portrait: {
          font: navItemMobile,
          fontSize: 30,
          fontWeight: 300,
          lineheight: 1.6
        },
        landscape: {
          font: navItemMobile,
          fontSize: 14,
          fontWeight: 300,
          lineheight: 1.6
        },
      },
      {
        breakpoint: 1,
        portrait: {
          font: navItemMobile,
          fontSize: 30,
          fontWeight: 300,
          lineheight: 1.6
        },
        landscape: {
          font: navItemMobile,
          fontSize: 30,
          fontWeight: 300,
          lineheight: 1.6
        },
      },
      {
        breakpoint: 2,
        portrait: {
          font: navItemDesktop,
          fontSize: 16,
          fontWeight: 300,
          lineheight: 1.6
        },
        landscape: {
          desktop:{
            font: navItemDesktop,
            fontSize: 16,
            fontWeight: 300,
            lineheight: 1.6
          },
          tablet:{
            font: navItemDesktop,
            fontSize: 16,
            fontWeight: 300,
            lineheight: 1.6
          }
        },
      }
    ]
  },
};
