import React from 'react'
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import styled from 'styled-components';

import FlexContainer from '@components/common/flex-container'


const M3u8 = styled( ({ marginTop, height,  ...props }) => <video {...props} />)`
  width: ${({ width }) => (width)};
  height:${({ height }) => (height)};
  object-fit:cover;
`

class StandardPlayer extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)

    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){
      if (newProps !== this.props) {
        // const videoplayer = window.document.getElementById("livestream")
        // console.log("videoplayer ", videoplayer);
        // videoplayer.oncanplay = () => {
        //   alert("Can start playing video");
        // }
      }
    }

    onResize(){
      // this.forceUpdate()
    }

    render(){
      return (<FlexContainer
        width={this.props.width}
        height={this.props.height}
        backgroundcolor="clear"
        >
        <M3u8
          width={this.props.width}
          height={this.props.height}
          autoPlay
          muted
          controls
          >
          <source src="https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_16x9/bipbop_16x9_variant.m3u8" type="application/x-mpegURL" />
        </M3u8>
      </FlexContainer>)
    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    breakpoint: state.gridData.breakpoint,
    margin: state.gridData.margin,
    activePageIndex: state.activePageIndex
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(StandardPlayer);
