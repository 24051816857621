export default function(state=false, action) {
  switch(action.type){
    case "UPDATE_STYLEGUIDE":
      return action.payload;

    default:
      break;
  }
  return state;
}
