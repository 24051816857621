import React from 'react'
import Theme from './theme.js';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { updateStyleguide } from '../../redux/actions';

class StyleGuide extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        header: {
          font: 'din-2014'
        }
      }



      // console.log("Breakpoints : ", Breakpoints);
      this.updateProperties = this.updateProperties.bind(this)

      this.devMode = false
    }


    componentDidMount() {
      window.addEventListener('resize', this.updateProperties);
      window.addEventListener('orientationchange', this.updateProperties);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateProperties);
      window.removeEventListener('orientationchange', this.updateProperties);
    }

    componentDidUpdate(newProps) {
      if (this.props !== newProps) {
        this.updateProperties()
        if (this.devMode) {
          console.log("STYLEGUIDE PROPSUPDATED");
        }

      }
    }


    async updateProperties() {

      if (this.props.breakpoint === undefined) {
        return
      }

      // Define variables to update
      // var header
      // var inquire
      // var subheader
      // var sectionTitle
      var passwordProtectionScreen
      var navItem

      if (this.props.innerWidth > this.props.innerHeight){

        if (this.props.breakpoint > 1) {
          if (this.props.innerWidth > 1366) {
            // header = Theme.header.fontSet[this.props.breakpoint].landscape.desktop
            // subheader = Theme.subheader.fontSet[this.props.breakpoint].landscape.desktop
            // sectionTitle = Theme.sectionTitle.fontSet[this.props.breakpoint].landscape.desktop
            passwordProtectionScreen = Theme.passwordProtectionScreen.fontSet[this.props.breakpoint].landscape.desktop

            navItem = Theme.navItem.fontSet[this.props.breakpoint].landscape.desktop
          }
          else {
            // header = Theme.header.fontSet[this.props.breakpoint].landscape.tablet
            // subheader = Theme.subheader.fontSet[this.props.breakpoint].landscape.tablet
            // sectionTitle = Theme.sectionTitle.fontSet[this.props.breakpoint].landscape.tablet
            passwordProtectionScreen = Theme.passwordProtectionScreen.fontSet[this.props.breakpoint].landscape.tablet
            navItem = Theme.navItem.fontSet[this.props.breakpoint].landscape.tablet
          }
        }
        else {
          // header = Theme.header.fontSet[this.props.breakpoint].landscape
          // subheader = Theme.subheader.fontSet[this.props.breakpoint].landscape
          // sectionTitle = Theme.sectionTitle.fontSet[this.props.breakpoint].landscape
          passwordProtectionScreen = Theme.passwordProtectionScreen.fontSet[this.props.breakpoint].landscape
          navItem = Theme.navItem.fontSet[this.props.breakpoint].landscape
        }

      }
      else {
        // header = Theme.header.fontSet[this.props.breakpoint].portrait
        // subheader = Theme.subheader.fontSet[this.props.breakpoint].portrait
        // sectionTitle = Theme.sectionTitle.fontSet[this.props.breakpoint].portrait
        passwordProtectionScreen = Theme.passwordProtectionScreen.fontSet[this.props.breakpoint].portrait
        navItem = Theme.navItem.fontSet[this.props.breakpoint].portrait

      }


      this.props.updateStyleguide({
        colors: Theme.colors,
        passwordProtectionScreen: passwordProtectionScreen,
        navItem: navItem
      })

      if (this.devMode) {
        console.log("STYLEGUIDE UPDATED");
      }
    }

    render() {
      return(
        <div></div>
      )
    }

}

function mapStateToProps(state){
  return{
    breakpoint:state.gridData.breakpoint,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight
  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateStyleguide: updateStyleguide
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(StyleGuide);
