import React from "react"
import { Provider } from 'react-redux';
import {createStore} from 'redux';
import Reducers from '../../redux/reducers'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import PasswordProtectionScreen from '@components/passwordprotection'
import CardBrowser from '@components/card-browsers/click-to-drag-card-browser'


const store = createStore(Reducers);

// markup
class ClickAndSlideTest extends React.Component {

  constructor(props) {
  super(props)
    this.devMode = false
    // this.renderMetaData = this.renderMetaData.bind(this)
    // this.renderRoute = this.renderRoute.bind(this)
  }

  componentDidMount() {
    // globalHistory.listen(({ action }) => {
    //       window.scrollTo(0,0)
    // })
  }

  componentWillUnmount() {

  }

  componentDidUpdate(newProps) {
    if (newProps !== this.props) {
      this.forceUpdate()
    }
  }

  renderView() {
    if (this.props.approvedForAccess) {
      return <CardBrowser />
    } else {
      return <PasswordProtectionScreen />
    }
  }

  render() {
    //
    return (
      <div>
        {this.renderView()}
      </div>
    )

  }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    approvedForAccess: state.approvedForAccess,
    breakpoint: state.gridData.breakpoint,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    margin: state.gridData.margin
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ClickAndSlideTest);
