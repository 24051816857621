import React from 'react'
import Breakpoints from './breakpointData.js';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {updateScreenGridData} from '../../redux/actions';

class ScreenGridCalculator extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        innerWidth: 0,
        innerHeight: 0,
        collumns: 16,
        margin: 0,
        gutter: 0,
        cellDimension: 90,
        rowHeight: 0
      }

      this.updateGridProperties = this.updateGridProperties.bind(this)
      this.updateGridPropertiesInterval = 0
      this.isPerformingGridUpdate = false


      // console.log("Breakpoints : ", Breakpoints);
      this.updateInitialProperties = this.updateInitialProperties.bind(this)
      this.onOrientationChange = this.onOrientationChange.bind(this)
      this.orientationChanged = false
      this.orientationJustChanged = false



      this.devMode = false
    }


    componentDidMount() {
      this.orientationChanged = false

      if (window.innerWidth < window.innerHeight) {
        // Portrait
        this.initialWindowWidth = window.innerWidth
        this.initialWindowHeight = window.screen.height
      }
      else {
        // Landscape
        this.initialWindowWidth = window.screen.width
        this.initialWindowHeight = window.innerHeight
      }



      window.addEventListener('resize', this.updateGridProperties);
      window.addEventListener('orientationchange', this.onOrientationChange);

      this.updateGridProperties()
    }

    componentDidUpdate(newProps) {
      if (newProps !== this.props) {
          this.updateGridProperties()
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateGridProperties);
      window.removeEventListener('orientationchange', this.onOrientationChange);
    }

    onOrientationChange() {
      // console.log("ORIENTATION CHANGED IN SCREEN GRID CALCULATOR");
      this.orientationChanged = !this.orientationChanged
      this.orientationJustChanged = true
      // this.updateGridProperties()

    }

    updateInitialProperties() {

    }


    async updateGridProperties() {

      if (this.isPerformingGridUpdate) {
        return
      }
      // Set the width based of the actual inner Width
      var innerWidth
      var innerHeight

      // If its a desktop
      if (typeof window.orientation === 'undefined') {
        // console.log("DESKTOP");
        innerWidth = window.innerWidth
        innerHeight = window.innerHeight
      }
      // If its a tablet / Mobile
      else {
        // console.log("MOBILE OR TABLET");
        if (window.innerWidth < window.innerHeight){
          // Portrait
          innerWidth = window.innerWidth
          innerHeight = window.screen.height
        }
        else {
          // Landscape
          innerWidth = window.innerWidth
          innerHeight = window.screen.width
        }
      }

      var breakpointData
      var breakpoint


      for (var i = 0; i< Breakpoints.length; i++){

        if (Breakpoints[i].minimumWidth <= innerWidth
          && Breakpoints[i].maximumWidth >= innerWidth) {
            breakpoint = i
            breakpointData = Breakpoints[i]
            break;
        }
      }

      this.props.updateScreenGridData({
        breakpoint: breakpoint,
        devicePixelRatio: window.devicePixelRatio,
        margin: breakpointData.margin,
        maxWidth: 1440
      })
    }

    render() {
      return(
        <div></div>
      )
    }

}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateScreenGridData:updateScreenGridData
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ScreenGridCalculator);
