import React from "react"
import NavigationBar from '../components/navigation-bar'
import './index.css'

import { Helmet } from "react-helmet"
import { globalHistory, Router } from "@reach/router"

import { Provider } from 'react-redux';
import {createStore} from 'redux';
import Reducers from './../redux/reducers'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import ScreenGridCalculator from '@components/screenGridCalculator'
import Styleguide from '@components/styleguide'
import Landing from './landing'
import ClickAndSlideTest from './clickAndSlideTest'

//
// import { graphql } from "gatsby"
//
//
// import Work from '../layouts/work';
// import About from '../layouts/about';
// import Careers from '../layouts/collaborate';
// import Contact from '../layouts/contact';
// import Clients from '../layouts/clients';
// import Blog from '../layouts/blog';
// import Services from '../layouts/services';
//
// import TermsAndConditions from '../layouts/terms';
// import Privacy from '../layouts/privacy';
//
// import MuralizeTerms from '../layouts/muralize/terms';
//
// import Clotho from '../layouts/clotho';


// export const query = graphql`
//   query($slug: String!) {
//     markdownRemark(fields: { slug: { eq: $slug } }) {
//       html
//       fields {
//         slug
//       }
//       frontmatter {
//         page
//         extensions
//         metaTitle
//         metaDescription
//         ogImage
//         canonical
//       }
//     }
//   }
// `

const store = createStore(Reducers);

// markup
class IndexPage extends React.Component {

  constructor(props) {
  super(props)
    this.devMode = false
    // this.renderMetaData = this.renderMetaData.bind(this)
    // this.renderRoute = this.renderRoute.bind(this)
  }

  componentDidMount() {
    // globalHistory.listen(({ action }) => {
    //       window.scrollTo(0,0)
    // })
  }

  componentWillUnmount() {

  }

  componentDidUpdate(newProps) {
    // if (typeof window !== undefined) {
    //   setTimeout(() => {
    //     window.scrollTo(0,0)
    //   }, 300)
    // }
  }

  renderMetaData() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title>delasign | Creative design technology.</title>
        <meta name="title" content="delasign | Creative design technology." />
        <meta name="description" content="We create design technology solutions that strike at the nucleus of our clients challenges and provide a fluid, seamless collaboration." />
        <meta property="og:title" content="delasign | Creative design technology." />
        <meta property="og:description" content="We create design technology solutions that strike at the nucleus of our clients challenges and provide a fluid, seamless collaboration."/>
        <meta name="image" property="og:image" content="/_media/delasign.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="800" />
        <link href="https://www.delasign.com" rel="canonical" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"/>
      </Helmet>
    )
  }


  render() {
    return (
      <div>
      <Provider store={store}>
        <NavigationBar />
        {this.renderMetaData()}
        <ScreenGridCalculator />
        <Styleguide />
        <Router>
          <Landing path="/*" />
          <ClickAndSlideTest path="/cardslide" />
        </Router>
      </Provider>
      </div>
    )

  }
}

export default IndexPage
