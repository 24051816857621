import React from 'react'
import { Flex } from 'grid-styled';
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const FlexContainer = styled(({ ...props}) => <Flex {...props} />)`
  cursor: ${({ cursor }) => (cursor)};
  pointer-events: ${({ pointer }) => (pointer) ? pointer : ""};

  opacity: ${({ opacity }) => (opacity) ? opacity : ""};

  transition: ${({ transition }) => (transition) ? transition : ""};


  top: ${({ top }) => (top) ? top : ""};
  left: ${({ left }) => (left) ? left : ""};
  position: ${({ position }) => (position) ? position : ""};
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};
  min-width: ${({ minwidth }) => (minwidth) ? minwidth : ""};
  min-height: ${({ minheight }) => (minheight) ? minheight : ""};
  z-index: ${({ zindex }) => (zindex) ? zindex : ""};
  ${'' /* border: 1px solid white; */}
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : ""};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : ""};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  margin-left: ${({ marginleft }) => (marginleft) ? marginleft + "px" : ""};
  margin-right: ${({ marginright }) => (marginright) ? marginright + "px" : ""};

  padding-top: ${({ paddingtop }) => (paddingtop) ? paddingtop + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  border: ${({ border }) => (border)};
  border-radius: ${({ radius }) => (radius)};
  overflow: ${({ overflow }) => (overflow)};

  background-image: ${({ backgroundimage }) => (backgroundimage) ? ('url('+backgroundimage+')') : ""};
  background-size: ${({ backgroundsize }) => (backgroundsize) ? backgroundsize : "" };

  scroll-snap-align: ${({ scrollsnapalign }) => (scrollsnapalign) ? scrollsnapalign : ""};
  scroll-snap-type: ${({ scrollsnaptype }) => (scrollsnaptype) ? scrollsnaptype : ""};

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;


  transform: ${({ transform }) => (transform) ? transform : ""};
  color: ${({ color }) => (color) ? color : ""};


    ::-webkit-scrollbar {
      display: none;
    }


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`

const Image = styled( ({ height, padding, ...props}) => <img alt="" {...props} />)`
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  padding: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  object-fit: cover;
`

class Card extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        opacity: 0.6,
        isHoveringOver: false,
        isScrolling: false
      }

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)

      this.calculateOpacity = this.calculateOpacity.bind(this)
      this.onMouseEnter = this.onMouseEnter.bind(this)
      this.onMouseLeave = this.onMouseLeave.bind(this)

      this.scrollingTimeOut = 0
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)

      this.calculateOpacity()
      this.refreshScrollListener()
    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){
      if (newProps !== this.props) {
        this.refreshScrollListener()
      }
    }

    onResize(){
      // this.forceUpdate()
    }

    // MARK: Scroll Listener

    refreshScrollListener() {

      const container = window.document.getElementById("card-carrousel-container")



      container.removeEventListener("scroll", null)

      if (container !== null && this.props.breakpoint >= 1) {

          container.addEventListener('scroll', (e) => {
            window.clearTimeout(this.scrollingTimeOut)
            if (!this.state.isScrolling) {
                this.setState({ isScrolling : true, isHoveringOver: false })
            }

            this.scrollingTimeOut = setTimeout( () => {
              if (this.state.isScrolling) {
                  this.setState({ isScrolling : false })
              }
            }, 50)

            this.calculateOpacity()


          }, false)
      }
    }

    // MARK: Opacity

    calculateOpacity() {
      const cardInQuestion = window.document.getElementById("home-page-carrousel-card-"+this.props.index)
      // console.log("cardInQuestion");
      if (cardInQuestion === null) {
        return
      }
      let elementPosition = this.getCenterOfElement(cardInQuestion)
      let documentPosition = this.getCenterOfPage()
      let distance = this.calculateDistance(documentPosition, elementPosition)


      var fraction = distance / (this.props.width + this.props.margin)

      if (fraction > 1) {
        fraction = 1
      }

      const opacity = 1 - 0.4*fraction

      this.setState({
        opacity: opacity
      })

    }

    getCenterOfPage() {
      let x = window.innerWidth/2;
      let y = window.innerHeight/2;
      let coordinates = {x, y};
      return coordinates;
    }

    getCenterOfElement(element) {
      // console.log("Element : ", element);
      const boundingBox = element.getBoundingClientRect()
      let x = boundingBox.x + boundingBox.width/2
      let y = boundingBox.y + boundingBox.height/2
      let coordinates = {x, y};

      return coordinates;
    }

    calculateDistance(coordinates1, coordinates2) {
      let h = Math.abs(coordinates1.x - coordinates2.x);
      return h;
    }


    // MARK: Mouse Events

    onMouseEnter(e) {
      if (this.props.index !== this.props.activePageIndex) {
        this.setState({
          isHoveringOver: true
        })
      }

    }

    onMouseLeave(e) {
      if (this.props.index !== this.props.activePageIndex) {
        this.setState({
          isHoveringOver: false
        })
      }
    }

    // MARK: Breakpoint Renders

    renderMobile() {
      return(
        <FlexContainer
          id={"home-page-carrousel-card-"+this.props.index}
          minwidth={this.props.width+"px"}
          minheight={this.props.height+"px"}
          marginleft={this.props.marginleft}
          marginright={this.props.marginright}
          backgroundcolor={"white"}
          justifycontent="center"
          alignitems="center"
          fontsize="100"
        >
          {this.props.index}
        </FlexContainer>
      )
    }

    renderDesktop() {
      // backgroundimage={this.props.image}
      // backgroundsize={"100% 100%"}

      var transform = "translateX(0)"
      var opacity = this.state.opacity
      var transition = "0s"

      if (!this.state.isScrolling && this.props.allowTransformAnimations) {
        if (this.state.isHoveringOver || this.props.hoveringOverRelevantArrow) {
          if (this.props.index > this.props.activePageIndex) {
            transform = "translateX(-10px)"
          } else if (this.props.index < this.props.activePageIndex) {
            transform = "translateX(10px)"
          }
        }

        if (this.state.isHoveringOver || this.props.hoveringOverRelevantArrow) {
          opacity = 1
        }

        transition = "0.6s cubic-bezier(0.22, 1, 0.36, 1);"
      }


      return(
        <FlexContainer
          id={"home-page-carrousel-card-"+this.props.index}
          position="absolute"
          width={this.props.width+"px"}
          height={this.props.height+"px"}
          left={this.props.left+"px"}
          right={this.props.right+"px"}
          backgroundcolor={"red"}
          color="white"
          alignitems="center"
          justifycontent="center"
          opacity={opacity}
          transform={ transform }
          transition={ transition }
          onMouseEnter={ () => this.onMouseEnter() }
          onMouseLeave={ () => this.onMouseLeave() }
          onClick={() => console.log("clicked card at index"+this.props.index+"!")}
        >
          {this.props.index}
        </FlexContainer>
      )

      // <Image
      //   src={this.props.image}
      //   width="100%"
      //   height="100%"
      // />
    }

    render(){

      return this.props.breakpoint >= 1 ? this.renderDesktop() : this.renderMobile()



    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    breakpoint: state.gridData.breakpoint,
    margin: state.gridData.margin,
    activePageIndex: state.activePageIndex
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Card);
