import React from 'react';
import styled from 'styled-components';
import { Box } from 'grid-styled';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import { updateActiveRoute, updateApprovedForAccess, updateAccessType } from '../../redux/actions';

import FlexContainer from '../common/flex-container'

import Button from '../button'
import TypographicElement from '../typography'


const BoundingBox = styled( ({ padding, ...props}) => <Box {...props} />)`
  margin: auto;
  width: 100vw;
  height: ${({ height }) => (height) ? height + "px" : ""};
  margin-top: ${({ padding }) => (padding) ? padding + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: black;
  overflow: hidden;
`

const FormInput = styled( ({ width, height, ...props}) => <input {...props} />)`
  width: ${({ width }) => (width) ? width :""};
  height: ${({ height }) => (height) ? height + "px" :"40px"};
  color: rgba(255,255,255, 0.75);
  font-family: ${({ font }) => (font) ? font:""};
  font: inherit;
  font-size: ${({ fontSize }) => (fontSize) ? fontSize:""}px;
  font-weight: 200;
  background-color: black;
  outline: none;
  border: 0px;
  border-bottom: 1px solid rgba(255,255,255, 0.75);
  padding: 0;
  text-align: center;
  inputmode=“none”
`

class PasswordProtectionScreen extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      status: "None",
      available: "none",
      password: "",
      openSesame: "ecfeed"
    }
    // Mounted ?
    this._isMounted = false

    this.onOrientationChange = this.onOrientationChange.bind(this)
    this.orientationChanged = false

    this.onFocus = this.onFocus.bind(this)
    this.onKeyPress = this.onKeyPress.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
    this.onAttemptToAccess = this.onAttemptToAccess.bind(this)


    // Devmode
    this.devMode = false


  }

  componentDidMount() {
    // Mounted
    this._isMounted = true
    window.addEventListener('orientationchange', this.onOrientationChange);
    // window.addEventListener('onresize', this.updateCollectionViewState);

    window.addEventListener("focus", this.onFocus)

    window.addEventListener("scroll", this.onScroll)


    window.document.addEventListener("keyup", this.onKeyPress)

  }

  componentWillUnmount() {
    // Not Mounted
    this._isMounted = false
    window.removeEventListener('orientationchange', this.onOrientationChange);
    // window.removeEventListener('onresize', this.updxateCollectionViewState);

    window.removeEventListener("focus", this.onFocus)

    window.removeEventListener("scroll", this.onScroll)

    window.document.removeEventListener("keyup", this.onKeyPress)
  }


  componentDidUpdate(newProps) {

  }

  onFocus(){
    this.forceUpdate()
  }

  onOrientationChange() {
    // console.log("ORIENTATION CHANGED ON LANDING");
    this.orientationChanged = !this.orientationChanged
  }

  onScroll() {

  }

  onKeyPress(event) {
    if (event.keyCode === 13) {
        this.onAttemptToAccess()
    }
  }

  onAttemptToAccess() {
    // const form = window.document.getElementById("password-form")
    // form.blur()
    // window.document.focus()

    this.hideKeyboard()
    const self = this
    setTimeout(function() {

      if (self.state.password === self.state.openSesame) {
        self.props.updateApprovedForAccess(true)
      } else {
        self.setState({ password: "" });
      }
      window.scrollTo(0,0)
    }, 100)
  }

  hideKeyboard() {
  //this set timeout needed for case when hideKeyborad
  //is called inside of 'onfocus' event handler
  setTimeout(function() {
    //creating temp field
    var field = document.createElement('input');
    field.setAttribute('type', 'text');
    //hiding temp field from peoples eyes
    //-webkit-user-modify is nessesary for Android 4.x
    field.setAttribute('style', 'position:absolute; top: 0px; opacity: 0; -webkit-user-modify: read-write-plaintext-only; left:0px;');
    document.body.appendChild(field);

    //adding onfocus event handler for out temp field
    field.onfocus = function(){
      //this timeout of 200ms is nessasary for Android 2.3.x
      setTimeout(function() {

        field.setAttribute('style', 'display:none;');
        setTimeout(function() {
          document.body.removeChild(field);
          document.body.focus();
        }, 14);

      }, 200);
    };
    //focusing it
    field.focus();

  }, 50);
}

  onRequestAccess() {

  }

  onPasswordChange(event) {
      this.setState({ password: event.target.value.toLowerCase() });
  }

  renderWindow() {


    return(<FlexContainer
      width={"100vw"}
      height={"100%"}
      justifycontent={"center"}
      alignitems={"center"}
      padding={0}
      margintop={0}
      backgroundcolor={"black"}
    >
    <FlexContainer
      flexdirection="column"
      width={"350px"}
      height={"100%"}
      justifycontent={"center"}
      alignitems={"center"}
      padding={0}
      margintop={3*this.props.margin}
      backgroundColor={"black"}
    >
    <FormInput
      id="password-form"
      width="350px"
      placeholder="* Enter a password"
      font={this.props.passwordProtectionScreen.font}
      fontSize={this.props.passwordProtectionScreen.fontSize}
      value={this.state.password}
      onChange={this.onPasswordChange}
      />
      <Button
        onClick={() => this.onAttemptToAccess()}
        >
        Enter
      </Button>
      </FlexContainer>
    </FlexContainer>)
  }

  render() {

    if (this.props.margin === undefined || this.props.passwordProtectionScreen === undefined) {
      return <div/>
    }

    // const width = this.props.innerWidth > this.props.maxWidth ? this.props.maxWidth : this.props.innerWidth

    return(
      <div>
        <BoundingBox
        height={window.innerHeight}
        >
          {this.renderWindow()}
        </BoundingBox>
      </div>
    )






  }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateActiveRoute: updateActiveRoute,
    updateApprovedForAccess: updateApprovedForAccess,
    updateAccessType: updateAccessType
  }, dispatch);
}

function mapStateToProps(state){
  return{
    colors: state.styleguide.colors,
    passwordProtectionScreen: state.styleguide.passwordProtectionScreen,
    body: state.styleguide.body,
    margin: state.gridData.margin,
    breakpoint: state.gridData.breakpoint,
    devicePixelRatio: state.gridData.devicePixelRatio,
    maxWidth: state.gridData.maxWidth
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordProtectionScreen);
