import React from 'react'
import ReactHlsPlayer from 'react-hls-player';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import styled from 'styled-components';

import FlexContainer from '@components/common/flex-container'


const M3u8 = styled( ({ marginTop, height,  ...props }) => <ReactHlsPlayer {...props} />)`
  width: ${({ width }) => (width)};
  height:${({ height }) => (height)};
  object-fit:cover;
`

class HLSPlayer extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)

    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){
      if (newProps !== this.props) {
        // const videoplayer = window.document.getElementById("livestream")
        // console.log("videoplayer ", videoplayer);
        // videoplayer.oncanplay = () => {
        //   alert("Can start playing video");
        // }
      }
    }

    onResize(){
      // this.forceUpdate()
    }

    renderMobile() {
      const link = "https://dacastmmd.mmdlive.lldns.net/dacastmmd/bcd20892e3f84e45b3ffd9ecd779f7be/manifest.m3u8?p=79&h=aaa5be309a1f8b18edca6537be2c86cc"
      // Apple Sample
      // "https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_16x9/bipbop_16x9_variant.m3u8"

      return(
        <FlexContainer
          width={this.props.width}
          height={this.props.height}
          backgroundcolor="clear"
          >
          <M3u8
              width={this.props.width}
              height={this.props.height}
              src={link}
              poster="https://athena.delasign.com/_media/prototype/cards/mobile/3-featuredevent.webp"
              controls
                />
        </FlexContainer>)
    }

    renderDesktop() {

      const link = "https://dacastmmd.mmdlive.lldns.net/dacastmmd/bcd20892e3f84e45b3ffd9ecd779f7be/manifest.m3u8?p=79&h=aaa5be309a1f8b18edca6537be2c86cc"

      // Apple Sample
      // "https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_16x9/bipbop_16x9_variant.m3u8"
      return(
        <FlexContainer
          width={this.props.width}
          height={this.props.height}
          backgroundcolor="clear"
          >
          <M3u8
              width={this.props.width}
              height={this.props.height}
              src={link}
              autoPlay
              muted

                />
        </FlexContainer>)
    }

    render(){

      if (this.props.breakpoint === undefined) {
        return <div/>
      }


      return this.props.breakpoint <= 1 ? this.renderMobile() : this.renderDesktop()
    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    breakpoint: state.gridData.breakpoint,
    margin: state.gridData.margin,
    activePageIndex: state.activePageIndex
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(HLSPlayer);
