import React from 'react'
import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';
import { Logo, LogoContainer } from '../../components/logo.js'
import { StyledRouterLink, Hamburger } from '@components/common'

import SampleCards from '@data/samplecards'

import Image from '@components/common/image'
import FlexContainer from '@components/common/flex-container'
import Pagination from '@components/pagination'

import PortraitMenu from './portrait'
import LandscapeMenu from './landscape'

import { updateScrollingEnabled, updateSameRouteSet } from '../../../../../../redux/actions';

import { navigate } from 'gatsby'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';



const BoundingBox = styled( ({ padding, overflowHidden, height, ...props }) => <Box {...props} />)`
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;
  transition: 0s;
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  overflow: hidden;
`

const NavigationBarContentContainer = styled( ({float, width, marginLeft, marginLeftMobile, marginRight, marginRightMobile, ...props}) => <Flex {...props} />)`
  float:${({ float }) => (float)};
  margin-left: ${({ marginLeft }) => (marginLeft)}px;
  margin-right: ${({ marginRight }) => (marginRight)}px;
  width: ${({ width }) => (width)};
  height: 60px;
  justify-content: center;
  align-items: center;
`


class AlternativeNavigationBar extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        isActive: false,
        transition: "0s",
        routeUpdate: false
      }

      this.menuIconClicked = this.menuIconClicked.bind(this)
      this.renderMenu = this.renderMenu.bind(this)


      // Transition Variables and Funcs
      this.onResize = this.onResize.bind(this)
      this.onOrientationChange = this.onOrientationChange.bind(this)


    }

    componentDidMount() {

      // Resize evetn listner
      window.addEventListener("resize", this.onResize)
      window.addEventListener('orientationchange', this.onOrientationChange);
    }

    componentWillUnmount() {
      // Resize evetn listner
      window.removeEventListener("resize", this.onResize)
      window.removeEventListener('orientationchange', this.onOrientationChange);
    }

    componentDidUpdate(newProps) {

    }



    onResize() {
      this.transition = "0s"
      this.setState({
        transition: "0s",
      })
    }

    onOrientationChange() {
      this.transition = "0s"
      this.setState({
        transition: "0s",
      })
    }

    getColorFromStyleguide(name) {
      if (this.props.colors === undefined) {
        return "red"
      }
      switch(name) {
        case "red":
          return this.props.colors.red
        case "white":
          return this.props.colors.white
        case "black":
            return this.props.colors.black
        case "paginationOffBlack":
          return this.props.colors.paginationOffBlack
        case "paginationOffWhite":
          return this.props.colors.paginationOffWhite
      }
    }






    menuIconClicked() {
      this.props.updateScrollingEnabled(true)
      this.setState({
        transition: "0.67s",
        isActive: !this.state.isActive
      })
    }

    onIdentityNavLinkClick(title) {

      if (this.props.route === "/") {
        // Navigate to that place
        this.props.updateSameRouteSet(true)
        window.history.pushState("", "", "/");
        setTimeout(()=>{
          this.props.updateSameRouteSet(false)
        }, 100)
      } else {
        navigate("/")
        this.props.updateSameRouteSet(false)
      }
    }

    renderMenu() {

      switch (this.state.isActive) {
        case true:
          if (window.innerWidth < window.innerHeight) {
            return(
              <PortraitMenu
                navigationLinks={this.props.navigationLinks}
                refreshMenu={(toRoute)=>this.refreshMenu(toRoute)}
                />
            )
          }
          else {
            return(
              <LandscapeMenu
                navigationLinks={this.props.navigationLinks}
                refreshMenu={(toRoute)=>this.refreshMenu(toRoute)}
                />
            )
          }
        default:
          return
      }


    }

    renderMenuButton() {
      var white = "white"
      var redShadow = "red"
      var red = "red"
      if (this.props.colors !== undefined) {
        white = this.props.colors.white
        redShadow = this.props.colors.redshadow
        red = this.props.colors.red
      }
      return(
        <FlexContainer
          position="absolute"
          right={this.props.margin+"px"}
          bottom={this.props.margin+"px"}
          backgroundcolor={white}
          height="64px"
          width="64px"
          padding={this.props.margin}
          pointer="all"
          borderradius="32px"
          zindex="1000"
          boxshadow={"0px 2px " + redShadow}
        >
          <Hamburger
            margin={this.props.margin}
            onClick={()=>this.menuIconClicked()}
            active={this.state.isActive}
            backgroundcolor={red}
            />
        </FlexContainer>)
    }

    getLogoColor() {
      if (this.state.isActive) {
        return this.getColorFromStyleguide("red")
      } else {
        return this.getColorFromStyleguide(SampleCards[this.props.activePageIndex].mobileNavigation.logo)
      }
    }

    getNavigationUnderlineColor() {
      if (this.state.isActive) {
        return "rgba(0,0,0,0)"
      } else {
        const underlinecolor = SampleCards[this.props.activePageIndex].mobileNavigation.underlinecolor
        return this.getColorFromStyleguide(underlinecolor)
      }
    }

    renderUnderline() {
      const route = this.props.route
      // console.log("route : ", route);
      if (route == "cardslide"
      || route == "cardslide/"
      || route == "/cardslide"
      || route == "/cardslide/") {
        return <div />
      }

      return(<FlexContainer
        position="absolute"
        top="64px"
        left="0"
        right="0"
        height="1px"
        zindex="100000"
        backgroundcolor={this.getNavigationUnderlineColor()}
      />)
    }

    renderTopNavigationBar() {
      return(<FlexContainer
        backgroundcolor={"clear"}
        height="64px"
        width="100vw"
        padding={this.props.margin}
        pointer="all"
        justifycontent="space-between"
        alignitems="center"
        backgroundcolor="clear"
      >
      <StyledRouterLink to={"/"}>
        <FlexContainer
          position="absolute"
          top="12px"
          left="12px"
          height="40px"
          width="40px"
          zindex="10"
        >
          <div>
            <svg version="1.1"x="0px" y="0px" width="30.02px"
            	 height="30px" viewBox="0 0 30.02 30" fill={this.getLogoColor()}>
            <path d="M0,6.12h14.05V0.2H0V6.12z M0,17.96h14.05v-5.92H0V17.96z M0,29.81h14.05v-5.92H0V29.81z M30.02,0
            	c-8.38,0-15.16,6.72-15.16,15c0,8.29,6.79,15,15.16,15V0z"/>
            </svg>
          </div>
        </FlexContainer>
      </StyledRouterLink>
      { this.renderPagination() }
      {this.renderUnderline()}
      </FlexContainer>
      )

      // <Image
      //   display="inline-block"
      //   width="40"
      //   height="40"
      //   background="/_media/ec-logo-mobile.svg"
      // />
    }

    renderPagination() {
      const route = this.props.route
      // console.log("Update ! : ", route);
      if (route == "cardslide"
      || route == "cardslide/"
      || route == "/cardslide"
      || route == "/cardslide/"
      || this.props.colors === undefined) {
        return <div />
      }

      return this.state.isActive ? <div/> : <Pagination height="64px" />
    }

    render(){


      return(
        <BoundingBox
          height={window.innerHeight}
          transition={this.state.transition}
          backgroundcolor={this.state.isActive ? this.props.colors.white : "clear"}
          >
          {this.renderTopNavigationBar()}
          {this.renderMenuButton()}
          {this.renderMenu()}
        </BoundingBox>
      )
    }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateScrollingEnabled: updateScrollingEnabled,
    updateSameRouteSet: updateSameRouteSet
  }, dispatch);
}


function mapStateToProps(state){
  return{
    approvedForAccess: state.approvedForAccess,
    activePageIndex: state.activePageIndex,
    margin: state.gridData.margin,
    colors: state.styleguide.colors
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(AlternativeNavigationBar);
