import React from 'react'
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { Logo, LogoContainer } from '../../components/logo.js'
import { StyledRouterLink } from '../../../../../common'

import FlexContainer from '../../../../../common/flex-container'

import { navigate } from 'gatsby'


import { updateScrollingEnabled, updateSameRouteSet } from '../../../../../../redux/actions';

// import ProjectData from './../../../../layouts/work/data/objectData'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const BoundingBox = styled( ({ padding, ...props }) => <Flex {...props} />)`
  position: fixed;
  z-index: 1000;
  margin: auto;
  width: ${({ width }) => (width) ? width + "px" : ""};
  padding-left: ${({ padding }) => (padding)}px;
  padding-right: ${({ padding }) => (padding)}px;
  align-items: center;
  top: 0;
  right: 0;
  left: 0;
  height: 116px;
  background-color: ${({ backgroundColor }) => (backgroundColor)};
`

const NavigationBarContentContainer = styled( ({float, width, marginLeft, marginLeftMobile, marginRight, marginRightMobile, ...props}) => <Flex {...props} alt="Logo"/>)`
  float:${({ float }) => (float)};
  margin-left: ${({ marginLeft }) => (marginLeft)}px;
  margin-right: ${({ marginRight }) => (marginRight)}px;
  width: ${({ width }) => (width)};
  height: 116px;
  justify-content: center;
  align-items: center;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};

  text-decoration: ${({ underline }) => (underline) ? "underline" : ""};

  color: ${({ color }) => (color)};

  :hover {
    color: ${({ hover }) => (hover)};
  }
`

const NavigationBarLinksContainer = styled( ({marginRight, marginRightMobile, width, ...props}) => <Flex {...props} alt="Logo"/>)`
  position: absolute;
  right: 0;
  float:right;
  margin-left: ${({ marginleft }) => (marginleft)}px;
  margin-right: ${({ marginRight }) => (marginRight)}px;
  width: ${({ width }) => (width)};
  height: 60px;
  justify-content: flex-end;
  align-items: center;
`



class DesktopNavigationBar extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }

      this.renderNavLinks = this.renderNavLinks.bind(this)

      this.onNavLinkClick = this.onNavLinkClick.bind(this)

      this.route = "/"
    }

    componentDidMount() {
      this.route = window.location.pathname

      // for (var i =0; i < ProjectData.length; i++) {
      //   const projectExtensions = ProjectData[i].extensions
      //   for (var j = 0; j < projectExtensions.length; j++) {
      //     const currentExtension = projectExtensions[j]
      //     if (this.route === "/" + currentExtension || this.route === "/" + currentExtension + "/") {
      //       this.route = "/"
      //     }
      //   }
      // }
    }

    componentDidUpdate(newProps) {

    }

    onNavLinkClick(title) {

      // if (this.route === "/") {
      //   // Navigate to that place
      //   this.props.updateSameRouteSet(true)
      //   window.history.pushState("", "", "/");
      //   setTimeout(()=>{
      //     this.props.updateSameRouteSet(false)
      //   }, 100)
      // } else {
      //
      //   for (var i =0; i < ProjectData.length; i++) {
      //     const projectExtensions = ProjectData[i].extensions
      //     for (var j = 0; j < projectExtensions.length; j++) {
      //       const currentExtension = projectExtensions[j]
      //       if (this.route === "/" + currentExtension || this.route === "/" + currentExtension + "/") {
      //         navigate("/")
      //         return
      //       }
      //     }
      //   }
      //
      //   this.props.updateSameRouteSet(false)
      // }
    }

    renderNavLinks() {
      const links = this.props.navigationLinks.map((item, index) => {
        if (index === this.props.navigationLinks.length-1) {
          return (
            <StyledRouterLink
              key={index}
              to={item["navLinkUrl"]}
              >
              <NavigationBarContentContainer
              key={index}
              float="right"
              width="auto"
              marginRight="0"
              font={this.props.navItem.font}
              fontSize={this.props.navItem.fontSize}
              fontWeight={this.props.navItem.fontWeight}
              lineheight={this.props.navItem.lineheight}
              onClick={()=> this.onNavLinkClick(item["navLinkTitle"])}
              // underline={item["navLinkTitle"] === this.props.activeRoute}
              color="black"
              hover={this.props.colors.red}
              >
              {item["navLinkTitle"]}
            </ NavigationBarContentContainer>
          </StyledRouterLink>
        )
        }
        else {
          return (
            <StyledRouterLink
              key={index}
              to={item["navLinkUrl"]}>
            <NavigationBarContentContainer
            key={index}
            float="right"
            width="auto"
            marginRight={40}
            font={this.props.navItem.font}
            fontSize={this.props.navItem.fontSize}
            fontWeight={this.props.navItem.fontWeight}
            lineheight={this.props.navItem.lineheight}
            onClick={()=> this.onNavLinkClick(item["navLinkTitle"])}
            // underline={item["navLinkTitle"] === this.props.activeRoute}
            color="black"
            hover={this.props.colors.red}
            >
            {item["navLinkTitle"]}
          </ NavigationBarContentContainer>
          </StyledRouterLink>
        )
        }
      })

      return(
        <FlexContainer
          marginright={this.props.margin}
          width="100%"
          color="black"
          >
          {links}
          <FlexContainer
            marginleft={this.props.margin}
            onClick={()=>{}}
            width="auto"
            height="116px"
            alignitems="center"
            >
            <Logo src={"/_media/search.png"} />
          </FlexContainer>
        </FlexContainer>
      )
    }

    render(){

      if (this.props.navItem === undefined) {
        return <div/>
      }

      const width = this.props.innerWidth // > this.props.maxWidth ? this.props.maxWidth : this.props.innerWidth

      return(
        <FlexContainer
          position="fixed"
          top="0"
          left="0"
          height="116px"
          width={"100vw"}
          padding={this.props.margin*2}
          backgroundcolor={"white"}
          zindex="1000000"
          justifycontent="space-between"
          alignitems="center"
          >
          <StyledRouterLink to={"/"}>
            <NavigationBarContentContainer
              float="left"
              width="auto"
              onClick={()=> this.onNavLinkClick("/")}
              color="black"
              marginLeft={this.props.margin}
              >
                <FlexContainer
                  onClick={()=> this.onNavLinkClick("/")}
                  >
                  <Logo src={"/_media/athena_logo.png"} />
                </FlexContainer>
            </ NavigationBarContentContainer>
            </StyledRouterLink>
          <div>
            {this.renderNavLinks()}
          </div>
        </FlexContainer>
      )
    }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateScrollingEnabled: updateScrollingEnabled,
    updateSameRouteSet: updateSameRouteSet
  }, dispatch);
}

function mapStateToProps(state){
  return{
    approvedForAccess: state.approvedForAccess,
    colors: state.styleguide.colors,
    navItem: state.styleguide.navItem,
    activeRoute: state.activeRoute,
    collumns: state.gridData.collumns,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    margin: state.gridData.margin,
    gutter: state.gridData.gutter,
    cellDimension: state.gridData.cellDimension,
    rowHeight: state.gridData.rowHeight,
    maxWidth: state.gridData.maxWidth
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(DesktopNavigationBar);
