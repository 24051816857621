import React from 'react'
import BreakPoints from '../../../screenGridCalculator/breakpointData'
import { DesktopNavigationBar, AlternativeNavigationBar } from './modalities'

import { globalHistory } from "@reach/router"
import {connect} from 'react-redux';

class Unlocked extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        route: ""
      }

      this.renderNavigationBar = this.renderNavigationBar.bind(this)

      this.navigationLinks = [
        {
          "navLinkTitle"  : "Home",
          "navLinkUrl"    : "/home"
        },
        {
          "navLinkTitle"  : "Latest Projects",
          "navLinkUrl"    : "/latestprojects"
        },
        {
          "navLinkTitle"  : "Resources",
          "navLinkUrl"    : "/resources"
        },
        {
          "navLinkTitle"  : "Events",
          "navLinkUrl"    : "/events"
        },
        {
          "navLinkTitle"  : "How We Work",
          "navLinkUrl"    : "/howwework"
        },
        {
          "navLinkTitle"  : "Programs",
          "navLinkUrl"    : "/programs"
        },
        {
          "navLinkTitle"  : "About Us",
          "navLinkUrl"    : "/about"
        }
      ]

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)
      this.onRouteChange = this.onRouteChange.bind(this)
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)


      if (typeof window !== 'undefined') {
        this.onRouteChange()
      }

      globalHistory.listen(({ action }) => {
        this.onRouteChange()
      })
    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){

    }

    onRouteChange() {
      this.setState({
        route: window.location.pathname
      })
    }


    onResize(){
      // this.forceUpdate()
    }

    renderNavigationBar() {

      if (this.props.breakpoint > 1) {
        return(
          <DesktopNavigationBar
            navigationLinks={this.navigationLinks}
            route={this.state.route}
            />
        )
      }
      else {
        return(
          <AlternativeNavigationBar
            navigationLinks={this.navigationLinks}
            route={this.state.route}
            />
        )
      }
    }

    render(){
      return(
        <div>
          {this.renderNavigationBar()}
        </div>
      )

    }
}

function mapStateToProps(state){
  return{
    breakpoint: state.gridData.breakpoint,
    margin: state.gridData.margin,
    gutter: state.gridData.gutter,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight
  }
}
export default connect(mapStateToProps, null)(Unlocked);
