export default function(state={
  active: false,
  transition: "0s"
}, action) {
  switch(action.type){
    case "UPDATE_INQUIRE_FORM":
      return action.payload;

    default:
      break;
  }
  return state;
}
