import React from 'react'
import { Flex } from 'grid-styled';
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const FlexContainer = styled(({ ...props}) => <Flex {...props} />)`
  cursor: ${({ cursor }) => (cursor)};
  pointer-events: ${({ pointer }) => (pointer) ? pointer : ""};
  top: ${({ top }) => (top) ? top : ""};
  left: ${({ left }) => (left) ? left : ""};
  position: ${({ position }) => (position) ? position : ""};
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};
  min-width: ${({ minwidth }) => (minwidth) ? minwidth : ""};
  min-height: ${({ minheight }) => (minheight) ? minheight : ""};
  z-index: ${({ zindex }) => (zindex) ? zindex : ""};
  ${'' /* border: 1px solid white; */}
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : ""};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : ""};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  margin-left: ${({ marginleft }) => (marginleft) ? marginleft + "px" : ""};
  margin-right: ${({ marginright }) => (marginright) ? marginright + "px" : ""};

  padding-top: ${({ paddingtop }) => (paddingtop) ? paddingtop + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  border: ${({ border }) => (border)};
  border-radius: ${({ radius }) => (radius)};
  overflow: ${({ overflow }) => (overflow)};

  background-image: ${({ backgroundimage }) => (backgroundimage) ? ('url('+backgroundimage+')') : ""};
  background-size: ${({ backgroundsize }) => (backgroundsize) ? backgroundsize : "" };

  scroll-snap-align: ${({ scrollsnapalign }) => (scrollsnapalign) ? scrollsnapalign : ""};
  scroll-snap-type: ${({ scrollsnaptype }) => (scrollsnaptype) ? scrollsnaptype : ""};

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;



    ::-webkit-scrollbar {
      display: none;
    }


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

`

const Image = styled( ({ height, padding, ...props}) => <img alt="" {...props} />)`
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  padding: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
`

class Card extends React.Component {

    constructor(props){
      super(props);
      this.state = {
        isPressed: false,
        offset: 0
      }

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)

      this.initialX = 0
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)
    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){

    }

    onResize(){
      // this.forceUpdate()
    }

    onTouchesStart(e) {
      if (this.props.activeIndex !== this.props.index) {
        return
      }
      this.initialX = e.targetTouches[0].screenX
      // console.log("Start : ", this.props.index);
      this.setState({
        isPressed: true
      })
    }

    onTouchesMoved(e) {
      if (this.props.activeIndex !== this.props.index) {
        return
      }
      const offset = e.targetTouches[0].screenX-this.initialX
      // console.log("Move X : ", offset);
      this.setState({
        offset: offset
      })

    }

    onTouchesEnded(e) {
      if (this.props.activeIndex !== this.props.index) {
        return
      }
      const offset = this.state.offset
      if (offset > 100) {
          // RIGHT
          this.props.onRelease(this.props.index, offset, "right")
      } else if (this.state.offset < -100) {
          // LEFT
          this.props.onRelease(this.props.index, offset, "left")
      }
      //

      // console.log("End : ", offset);

      this.setState({
        isPressed: false,
        offset: 0
      })
    }

    render(){
      if (this.props.margin === undefined) {
        return <div/>
      }
      return(
        <FlexContainer
          position={"absolute"}
          top={this.props.top+"px"}
          left={this.state.isPressed ? this.props.left + this.state.offset +"px" : this.props.left+"px"}
          width={this.props.width}
          height={this.props.height}
          zindex={this.props.zindex}
          onTouchStart={(e) => this.onTouchesStart(e)}
          onTouchMove={(e) => this.onTouchesMoved(e)}
          onTouchEnd={(e) => this.onTouchesEnded(e)}
        >
          <Image
            width={"100%"}
            height={"auto"}
            src={this.props.image}
          />
        </FlexContainer>
      )



    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    approvedForAccess: state.approvedForAccess,
    activeRoute: state.activeRoute,
    collumns: state.gridData.collumns,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    margin: state.gridData.margin,
    gutter: state.gridData.gutter,
    cellDimension: state.gridData.cellDimension,
    rowHeight: state.gridData.rowHeight,
    maxWidth: state.gridData.maxWidth
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Card);
