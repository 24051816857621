export default function(state=true, action) {
  switch(action.type){
    case "UPDATE_SCROLLING_ENABLED":
      return action.payload;

    default:
      break;
  }
  return state;
}
