export const updateScreenGridData = (newState) => {
  return{
    type:'UPDATE_SCREEN_GRID_DATA',
    payload:newState
  }
}

export const updateScrollingEnabled = (newState) => {
  return{
    type:'UPDATE_SCROLLING_ENABLED',
    payload:newState
  }
}

export const updateStyleguide = (newState) => {
  return{
    type:'UPDATE_STYLEGUIDE',
    payload:newState
  }
}

export const updateInquireForm = (newState) => {
  return{
    type:'UPDATE_INQUIRE_FORM',
    payload:newState
  }
}

export const updateSameRouteSet = (newState) => {
  return{
    type:'SAME_ROUTE_SET',
    payload:newState
  }
}

export const updateActiveRoute = (newState) => {
  return{
    type:'ACTIVE_ROUTE_SET',
    payload: newState
  }
}


export const updateApprovedForAccess = (newState) => {
  return{
    type:'UPDATE_APPROVED_FOR_STATUS',
    payload:newState
  }
}

export const updateActivePageIndex = (newState) => {
  return{
    type:'UPDATE_ACTIVE_PAGE_INDEX',
    payload:newState
  }
}
