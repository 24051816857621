import React from 'react'
import FlexContainer from '../../../common/flex-container'
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

const Logo = styled( ({ height, padding, ...props}) => <img alt="" {...props} />)`
  width: 40px;
  height: 40px;
  padding: ${({ padding }) => (padding) ? padding + "px" : ""};
  border-radius: 20px;
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
`

class Locked extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }

      // this.renderNavigationBar = this.renderNavigationBar.bind(this)

      this.navigationLinks = [
        // {
        //   "navLinkTitle"  : "about",
        //   "navLinkUrl"    : "/about"
        // },
        // {
        //   "navLinkTitle"  : "services",
        //   "navLinkUrl"    : "/services"
        // },
        // {
        //   "navLinkTitle"  : "work",
        //   "navLinkUrl"    : "/"
        // },
        // {
        //   "navLinkTitle"  : "clients",
        //   "navLinkUrl"    : "/clients"
        // },
        // {
        //   "navLinkTitle"  : "blog",
        //   "navLinkUrl"    : "/blog"
        // },
        // {
        //   "navLinkTitle"  : "careers",
        //   "navLinkUrl"    : "/careers"
        // },
        // {
        //   "navLinkTitle"  : "contact",
        //   "navLinkUrl"    : "/contact"
        // }
      ]

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)
    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){

    }

    onResize(){
      // this.forceUpdate()
    }

    render(){
      if (this.props.margin === undefined) {
        return <div/>
      }
      return(
        <div>
        <FlexContainer
          position="fixed"
          width={"100vw"}
          height={"116px"}
          justifycontent={"flex-start"}
          alignitems={"center"}
          backgroundcolor={"black"}
          margintop={"0px"}
          top="0px"
          left="0px"
          zindex="100000000000000000000"
        >
            <Logo
            backgroundcolor={"black"}
            src={"/_media/delasignWhite.webp"}
            padding={this.props.margin}
          />
        </FlexContainer>
        </div>
      )

    }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    approvedForAccess: state.approvedForAccess,
    activeRoute: state.activeRoute,
    collumns: state.gridData.collumns,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    margin: state.gridData.margin,
    gutter: state.gridData.gutter,
    cellDimension: state.gridData.cellDimension,
    rowHeight: state.gridData.rowHeight,
    maxWidth: state.gridData.maxWidth
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Locked);
