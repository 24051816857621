import { combineReducers } from 'redux';
import GridData  from './gridData';
import ScrollEnabled  from './scrolling';
import Styleguide  from './styleguide';
import InquireForm  from './inquireForm';
import SameRouteSet  from './sameRouteSet';
import ActiveRoute  from './activeRoute';
import ApprovedForAccess  from './approvedForAccess';
import ActivePageIndex  from './activePageIndex';

const allReducers = combineReducers({
  gridData: GridData,
  scrollEnabled: ScrollEnabled,
  styleguide: Styleguide,
  inquireForm: InquireForm,
  sameRouteSet: SameRouteSet,
  activeRoute: ActiveRoute,
  approvedForAccess: ApprovedForAccess,
  activePageIndex: ActivePageIndex
})

export default allReducers;
