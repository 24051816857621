import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {connect} from 'react-redux';
import { Flex } from 'grid-styled';

const Button = styled( ({ ...props}) => <Link {...props} />)`
  display: flex;
  border: 1px solid white;
  width: 100%;
  padding: 10px 0;

  max-width: 500px;

  transition: 0.67s;

  text-decoration:none;

  font: ${({ font }) => (font) ? font:""};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize:""}px;
  font-weight: 300;
  color: white;
  background-color: black;

  pointer-events: all !important;
  user-select: auto !important;
  cursor: pointer !important;

  justify-content: center;
  align-items: center;
  text-align: center;

  :hover {
    background-color: white !important;
    color: black !important;
    border: 1px solid black;
  }
`
const InverseButton = styled( ({ ...props}) => <Link {...props} />)`
  display: flex;
  border: 1px solid black;
  width: 100%;
  padding: 10px 0;

  max-width: 500px;

  transition: 0.67s;

  text-decoration:none;

  font: ${({ font }) => (font) ? font:""};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize:""}px;
  font-weight: 300;
  color: black;

  pointer-events: all !important;
  user-select: auto !important;
  cursor: pointer !important;

  justify-content: center;
  align-items: center;
  text-align: center;

  :hover {
    background-color: black !important;
    color: white !important;
  }
`

const ExternalButton = styled( ({ children, ...props}) => <a {...props}>{children}</a>)`
  display: flex;
  border: 1px solid white;
  width: 100%;
  padding: 10px 0;

  max-width: 500px;

  transition: 0.67s;

  text-decoration:none;

  font: ${({ font }) => (font) ? font:""};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize:""}px;
  font-weight: 300;
  color: white;

  pointer-events: all !important;
  user-select: auto !important;
  cursor: pointer !important;

  justify-content: center;
  align-items: center;
  text-align: center;

  :hover {
    background-color: white !important;
    color: black !important;
  }
`

const ButtonBox = styled( ({ padding, ...props}) => <Flex {...props} />)`
  width: 100%;
  padding: 10px 0;
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-left: ${({ marginleft }) => (marginleft) ? marginleft + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};
  justify-content: center;
  align-items: center;
  text-align: center;
`


class ButtonElement extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      breakpointData: [
        {
          breakpoint: 0,
          buttonHeight: 60
        },
        {
          breakpoint: 1,
          buttonHeight: 60
        },
        {
          breakpoint: 2,
          buttonHeight: 60
        }
      ]
    }
    // Mounted ?
    this._isMounted = false

    // Devmode
    this.devMode = false

  }

  componentDidMount() {
    // Mounted
    this._isMounted = true
  }

  componentWillUnmount() {
    // Not Mounted
    this._isMounted = false
  }

  render() {

    if (this.props.type === "external") {
      return(
          <ButtonBox
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            marginleft={this.props.marginleft}
            margintop={this.props.margintop}
            >
          <ExternalButton
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            font={this.props.font}
            fontSize={this.props.fontSize}
            href={this.props.to}
            target={"_blank"}
            dangerouslySetInnerHTML={{__html: this.props.children}}
            >

        </ExternalButton>
        </ButtonBox>
      )
    } if (this.props.type === "inverse") {
      return(
          <ButtonBox
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            marginleft={this.props.marginleft}
            margintop={this.props.margintop}
            >
              <InverseButton
                height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
                font={this.props.font}
                fontSize={this.props.fontSize}
                to={this.props.to}
                dangerouslySetInnerHTML={{__html: this.props.children}}
                >

              </InverseButton>
        </ButtonBox>
      )
    } else {

      if (this.props.onClick === undefined) {
        return(
          <ButtonBox
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            marginleft={this.props.marginleft}
            margintop={this.props.margintop}
            padding={this.props.padding}
            >
          <Button
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            font={this.props.font}
            fontSize={this.props.fontSize}
            to={this.props.to}
            dangerouslySetInnerHTML={{__html: this.props.children}}
            >

        </Button>
        </ButtonBox>
      )
      } else {
        return(
          <ButtonBox
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            marginleft={this.props.marginleft}
            margintop={this.props.margintop}
            padding={this.props.padding}
            onClick={() => {this.props.onClick()}}
            >
          <Button
            height={this.state.breakpointData[this.props.breakpoint].buttonHeight}
            font={this.props.font}
            fontSize={this.props.fontSize}
            to={this.props.to}
            dangerouslySetInnerHTML={{__html: this.props.children}}
            >

        </Button>
        </ButtonBox>
      )
      }

    }



  }
}

function mapStateToProps(state){
  return{
    header: state.styleguide.header,
    subheader: state.styleguide.subheader,
    margin: state.gridData.margin,
    gutter: state.gridData.gutter,
    rowHeight: state.gridData.rowHeight,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    breakpoint: state.gridData.breakpoint,
    devicePixelRatio: state.gridData.devicePixelRatio
  }
}

export default connect(mapStateToProps, null)(ButtonElement);
