import React from 'react'
import { Box, Flex } from 'grid-styled';
import styled from 'styled-components';

import FlexContainer from '@components/common/flex-container'
import { AutoSizedImage } from '@components/common/image'

import Pagination from '@components/pagination'
import Card from './components/card'

import SampleCards from '@data/samplecards'
import { updateActivePageIndex } from '@redux/actions'

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';


const BrowserContainer = styled(({ ...props}) => <Box {...props} />)`

  display: ${({ display }) => (display) ? display : ""};

  cursor: ${({ cursor }) => (cursor)};
  pointer-events: ${({ pointer }) => (pointer) ? pointer : ""};
  top: ${({ top }) => (top) ? top : ""};
  left: ${({ left }) => (left) ? left : ""};
  position: ${({ position }) => (position) ? position : ""};
  width: ${({ width }) => (width) ? width : ""};
  max-width: ${({ maxwidth }) => (maxwidth) ? maxwidth : ""};
  height: ${({ height }) => (height) ? height : ""};
  z-index: ${({ zindex }) => (zindex) ? zindex : ""};
  ${'' /* border: 1px solid white; */}
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : ""};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : ""};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  margin-left: ${({ marginleft }) => (marginleft) ? marginleft + "px" : ""};
  margin-right: ${({ marginright }) => (marginright) ? marginright + "px" : ""};
  padding-top: ${({ paddingtop }) => (paddingtop) ? paddingtop + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ paddingright }) => (paddingright) ? paddingright + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  border: ${({ border }) => (border)};
  border-radius: ${({ radius }) => (radius)};
  overflow: ${({ overflow }) => (overflow)};
  overflow-x: ${({ overflowx }) => (overflowx) ? overflowx : ""};
  overflow-y: ${({ overflowy }) => (overflowy) ? overflowy : ""};

  scroll-snap-align: ${({ scrollsnapalign }) => (scrollsnapalign) ? scrollsnapalign : ""};
  scroll-snap-type: ${({ scrollsnaptype }) => (scrollsnaptype) ? scrollsnaptype : ""};

  touch-action:${({ touchaction }) => (touchaction) ? touchaction : ""};

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  /* -webkit-overflow-scrolling: touch;


    ::-webkit-scrollbar {
      display: none;
    }


    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */ */

`

class SnapCardBrowser extends React.Component {

    // MARK: Lifecycle
    constructor(props){
      super(props);
      this.state = {
        currentIndex: 0,
        offset: 0,
        infiniteCarrouselWidth: 10000,//10000000
        allowTransformAnimations: true,
        isHoveringLeftPagination: false,
        isHoveringRightPagination: false,
        dimensions: [
          {
            breakpoint:0,
            width: 100,
            height: 100,
            aspectRatio: 1/1
          },
          {
            breakpoint:1,
            width: 90,
            aspectRatio: 1/1
          },
          {
            breakpoint:2,
            width: 90,
            aspectRatio: 866/640
          },
        ]
      }


      // Pagination Desktop
      this.onNextPage = this.onNextPage.bind(this)
      this.onLastPage = this.onLastPage.bind(this)
      this.scrollToIndex = this.scrollToIndex.bind(this)

      this.disableTransformAnimations = this.disableTransformAnimations.bind(this)

      this.onMouseEnterLeftPagination = this.onMouseEnterLeftPagination.bind(this)
      this.onMouseLeaveLeftPagination = this.onMouseLeaveLeftPagination.bind(this)

      this.onMouseEnterRightPagination = this.onMouseEnterRightPagination.bind(this)
      this.onMouseLeaveRightPagination = this.onMouseLeaveRightPagination.bind(this)

      // Scroll Listener Properties
      this.scrollTimeOut = 0
      this.initialOffset = 0
      this.eventsAdded = false
      // For Logs
      this.devMode = false
    }

    componentDidMount() {
      this.refreshScrollListener()
    }

    componentWillUnmount() {

    }

    componentDidUpdate(newProps){
      if (newProps !== this.props) {
        this.refreshScrollListener()
      }
    }

    // MARK : UI Dataset
    setDataPoints() {
      this.paginationBarHeight = 60
      this.margintop = this.props.breakpoint <= 1 ? 0 : 116

      this.height = this.props.breakpoint <= 1 ? (window.innerHeight) : (window.innerHeight-this.margintop-this.paginationBarHeight)
      this.width = window.innerWidth

      this.count = SampleCards.length

      switch (this.props.breakpoint) {
        case 0:
          this.cardWidth = this.width*1
          this.cardHeight = this.height*1
          break
        case 1:
          this.cardWidth = this.width*1
          this.cardHeight = this.height*1
          break
        case 2:

          const widthScale = 1
          const fraction = 0.5
          const aspectRatio = 640/866

          this.cardWidth = this.width*widthScale*fraction
          this.cardHeight = this.cardWidth*aspectRatio
      }

      this.scrollableDistancePerIndex = this.props.breakpoint > 1 ?
                               this.cardWidth + this.props.margin :
                               this.cardWidth;

      this.totalBrowserSize = this.props.breakpoint > 1 ?
                               (this.width-this.cardWidth) + (this.cardWidth+this.props.margin) * this.count :
                               this.cardWidth*this.count;


    }

    // MARK: Scroll Listener

    refreshScrollListener() {

      const container = window.document.getElementById("card-carrousel-container")
      const scrollContainer = window.document.getElementById("scrolling-container")

      if (this.devMode) {
        // console.log("carrousel", carrousel);
      }
      if (container !== null && !this.eventsAdded) {
          this.eventsAdded = true
          // Set Scroll

          scrollContainer.scroll({
            top: 0,
            left: this.state.infiniteCarrouselWidth/2
          })

          // scrollContainer.removeEventListener("click", null)
          // scrollContainer.addEventListener("click", (e) => {
          //     var evt = document.createEvent("MouseEvents");
          //     evt.initMouseEvent("click", true, true, window,
          //     0, 0, 0, 0, 0, false, false, false, false, 0, null);
          //     const card1 = window.document.getElementById("home-page-carrousel-card-0")
          //     card1.dispatchEvent(evt)
          //     console.log("click");
          //
          // }, false)

          // if (this.initialOffset === 0) {
          //   this.initialOffset = e.target.scrollLeft
          // }
          // // Determine the current Index
          // this.determineCurrentIndex(e.target.scrollLeft)


          scrollContainer.removeEventListener("scroll", null)
          scrollContainer.addEventListener('scroll', (e) => {
              // window.clearTimeout(this.scrollTimeOut)

          }, false)

          scrollContainer.addEventListener('mousewheel', (e) => {
              window.clearTimeout(this.scrollTimeOut)
              this.scrollTimeOut = setTimeout(()=>{
                  console.log("Stopped Scrolling");
              },50)
          }, false)

          scrollContainer.addEventListener("mousedown", this.handleStart, false);
          scrollContainer.addEventListener("mousemove", this.handleMove, false);
          scrollContainer.addEventListener("mouseup", this.handleEnd, false);
      }
    }

    handleStart(e) {
      console.log("Touch start");
    }

    handleMove(e) {
      console.log("Touch move");
    }

    handleEnd(e) {
      console.log("Touch ended");
    }


    determineCurrentIndex(scrollOffset) {

      var value = scrollOffset/this.scrollableDistancePerIndex
      var currentIndex = Math.floor(value)
      var decimal = value - currentIndex

      if (decimal > 0.5) {
        currentIndex += 1
      }

      if (currentIndex < 0) {
        currentIndex = 0
      } else if (currentIndex > this.count-1) {
        currentIndex = this.count - 1
      }

      if (this.props.activePageIndex !== currentIndex) {
        this.props.updateActivePageIndex(currentIndex)
      }
    }

    scrollToIndex(index) {
      const container = window.document.getElementById("card-carrousel-container")
      // container.scroll({
      //   top: 0,
      //   left: this.scrollableDistancePerIndex*index,
      //   behavior: 'smooth'
      // })
    }

    // MARK:  Desktop Mouse events

    // MARK: Mouse Events

    onMouseEnterLeftPagination(e) {
      this.setState({
        isHoveringLeftPagination: true
      })
    }

    onMouseLeaveLeftPagination(e) {
      this.setState({
        isHoveringLeftPagination: false
      })
    }

    onMouseEnterRightPagination(e) {
      this.setState({
        isHoveringRightPagination: true
      })
    }

    onMouseLeaveRightPagination(e) {

      this.setState({
        isHoveringRightPagination: false
      })
    }


    // MARK: UI

    renderCards() {
      var top = 0
      var left = 0
      var right = 0

      var cards = []
      for ( var i = 0; i < 5; i++ ) {

        var measuredIndex = this.props.activePageIndex - 2 + i

        if (measuredIndex < 0) {
          measuredIndex = measuredIndex + this.count
        }

        const currentCard = SampleCards[measuredIndex]
        if (this.props.breakpoint > 1) {
          left = -(this.cardWidth)*3/2 - 2*this.props.margin + (this.cardWidth + this.props.margin)*i
          right = 0
        } else {
          left = 0
          right = 0
        }

        var hoveringOverRelevantArrow = false

        if (this.state.isHoveringLeftPagination && i === this.props.activePageIndex -1) {
          hoveringOverRelevantArrow = true
        } else if (this.state.isHoveringRightPagination && i === this.props.activePageIndex+1) {
          hoveringOverRelevantArrow = true
        }

        cards.push(<Card
          key={"card-"+measuredIndex}
          index={measuredIndex}
          left={left}
          right={right}
          width={this.cardWidth}
          height={this.cardHeight}
          image={ this.props.breakpoint > 1 ? currentCard.image.desktop : currentCard.image.mobile }
          hoveringOverRelevantArrow={hoveringOverRelevantArrow}
          allowTransformAnimations={this.state.allowTransformAnimations}
          backgroundcolor="clear"
        />)
      }

      // if (this.props.breakpoint >= 1) {
      //   cards.push(<Card
      //     key={"card-"+10}
      //     index={10}
      //     zindex={this.count-10}
      //     marginleft={0}
      //     marginright={0}
      //     width={(this.width-this.cardWidth)/2}
      //     height={this.cardHeight}
      //     backgroundcolor={"white"}
      //   />)
      // }


      // return cards
      return(<BrowserContainer
        id="card-carrousel-container"
        position={"relative"}
        margintop={this.margintop}
        maxwidth="100vw"
        height={this.height+"px"}
        backgroundcolor={"clear"}
        scrollsnaptype="x mandatory"
        overflowx="scroll"
      >
          <BrowserContainer
            display="flex"
            alignitems="center"
            height={this.height+"px"}
            width={"100vw"}
            backgroundcolor={"clear"}
            overflowx="scroll"
            overflowy="hidden"
            scrollsnaptype="x mandatory"
        >
          {cards}
          </BrowserContainer>
      </BrowserContainer>)
    }

    renderPaginationBar() {
      if (this.props.breakpoint <= 1) {
        return <div/>
      } else {
        //

        const transition = "0.6s cubic-bezier(0.22, 1, 0.36, 1)"

        return(<FlexContainer
          position="absolute"
          width="100vw"
          top={this.height+this.margintop + "px"}
          left="0"
          height={this.paginationBarHeight+"px"}
          backgroundcolor="clear"
          justifycontent="space-between"
          alignitems="center"
          padding={this.props.margin}
          zindex="5"
          >
          <FlexContainer
            position="absolute"
            left={this.props.margin+30+"px"}
            top={(this.paginationBarHeight-16)/2+"px"}
            width="62px"
            height="16px"
            padding={this.props.margin}
            backgroundcolor="clear"
            zindex="5"
            onClick={ () => { this.onLastPage() }}
            onMouseEnter={ () => this.onMouseEnterLeftPagination() }
            onMouseLeave={ () => this.onMouseLeaveLeftPagination() }
            >
            <FlexContainer
              width="62px"
              height="16px"
              transition={ transition }
              transform={this.state.isHoveringLeftPagination ? "translateX(-30px)" : 0}
              >
                <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd" d="M0,8l7.96,8l0.72-0.73L1.96,8.51H32V7.49H1.96l6.72-6.76L7.96,0L0,8z" fill="black"/>
                </svg>
              </FlexContainer>
            </FlexContainer>
            <Pagination height={this.paginationBarHeight+"px"}/>
            <FlexContainer
              position="absolute"
              right={this.props.margin*2+30+"px"}
              top={(this.paginationBarHeight-16)/2+"px"}
              width="62px"
              height="16px"
              padding={this.props.margin}
              backgroundcolor="clear"
              zindex="15"
              onClick={ () => { this.onNextPage() }}
              onMouseEnter={ () => this.onMouseEnterRightPagination() }
              onMouseLeave={ () => this.onMouseLeaveRightPagination() }
              >
              <FlexContainer
                width="62px"
                height="16px"
                transition={ transition }
                transform={this.state.isHoveringRightPagination ? "translateX(30px)" : 0}
                >
                  <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M32,8l-7.96-8l-0.72,0.73l6.72,6.76H0v1.03h30.04l-6.72,6.76L24.04,16L32,8z" fill="black"/>
                  </svg>
                </FlexContainer>
            </FlexContainer>
        </FlexContainer>)


      }
    }

    onNextPage() {
      if (this.props.activePageIndex < SampleCards.length) {
        this.disableTransformAnimations()
        setTimeout(()=> {
          const newIndex = this.props.activePageIndex + 1
          this.scrollToIndex(newIndex)
        }, 1000/30)

      }
    }

    onLastPage() {
      if (this.props.activePageIndex > 0) {
          this.disableTransformAnimations()
          setTimeout(()=> {
            const newIndex = this.props.activePageIndex - 1
            this.scrollToIndex(newIndex)
          }, 1000/30)
      }
    }

    disableTransformAnimations() {
      this.setState({
        allowTransformAnimations: false
      })

      setTimeout(()=> {
        this.setState({
          allowTransformAnimations: true
        })
      }, 500)
    }

    // MARK: Profile
    renderProfile() {
      if (this.props.breakpoint > 1) {
        return <div/>
      }

      const activeItem = SampleCards[this.props.activePageIndex]
      if ( activeItem.type === "featured event") {

        return(<FlexContainer
          width="100%"
          height="auto"
          >
            <AutoSizedImage
                width="100vw"
                height="auto"
                src={activeItem.story}
              />
          </FlexContainer>)
      } else {
        return <div/>
      }
    }

    renderScrollableContainer() {
      return(<BrowserContainer
        id="scrolling-container"
        position={"absolute"}
        top={this.margintop+"px"}
        left="0"
        width={"100vw"}
        height={this.height+"px"}
        backgroundcolor={"transparent"}
        overflow="scroll"
        zindex={2}
      >
      <BrowserContainer
        position={"absolute"}
        pointer="none"
        touchaction="none"
        top="0"
        left="0"
        width={this.state.infiniteCarrouselWidth+"px"}
        height={this.height+"px"}
        backgroundcolor={"transparent"}
        overflow="auto"
      />
      </BrowserContainer>)
    }

    // MARK: RENDER

    render(){
      if (this.props.margin === undefined) {
        return <div/>
      }

      this.setDataPoints()


      return(<div>
        {this.renderCards()}
        {this.renderScrollableContainer()}
      </div>)
    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({
    updateActivePageIndex: updateActivePageIndex
  }, dispatch);
}

function mapStateToProps(state){
  return{
    breakpoint: state.gridData.breakpoint,
    innerWidth: state.gridData.innerWidth,
    innerHeight: state.gridData.innerHeight,
    margin: state.gridData.margin,
    activePageIndex: state.activePageIndex
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SnapCardBrowser);
