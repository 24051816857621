export default function(state=0, action) {
  switch(action.type){
    case "UPDATE_ACTIVE_PAGE_INDEX":
      return action.payload;

    default:
      break;
  }
  return state;
}
