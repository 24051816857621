import React from 'react'
import styled from 'styled-components';
import { Box, Flex } from 'grid-styled';

const Container = styled( ({active, margin, ...props}) => <Flex {...props}/>)`
  right: 0;
  margin-right: ${({ margin }) => (margin)}px;
  height: 64px;
  width: 64px;
  align-items: center;
  justify-content: center;
  background-color: clear;

`

const Bar = styled( ({top, left, opacity, rotate, translateY,  transition, marginLeft, delay, ...props}) => <Flex {...props}/>)`
  position: absolute;
  top:${({ top }) => (top)}px;

  width: 24px;
  height: 2px;
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
  transformOrigin: top left;
  transform: translateY(${({ translateY }) => (translateY)}px) rotate(${({ rotate }) => (rotate)}deg);
  margin-left: ${({ marginLeft }) => (marginLeft)}px;

  opacity: ${({ opacity }) => (opacity)};

  transition: ${({ transition }) => (transition)};
  transition-delay: ${({ delay }) => (delay)};

`
class Hamburger extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }
    }

    render(){
      return(
        <Container
          active={this.props.active}
          onClick={()=>this.props.onClick()}
          >
          <Bar
            top={22}
            translateY={()=> this.props.active ? 8:0}
            opacity={1}
            rotate={()=> this.props.active ? 45:0}
            transition={"0.33s"}
            delay={()=> this.props.active ? "0.1s":"0"}
            backgroundcolor={this.props.backgroundcolor}
            />
          <Bar
            top={30}
            translateY={0}
            marginLeft={()=> this.props.active ? 1000:0}
            transition={()=> this.props.active ? "1s":"0.33s"}
            delay={()=> this.props.active ? "0":"0"}
            rotate={0}
            backgroundcolor={this.props.backgroundcolor}
            />
          <Bar
            top={38}
            translateY={()=> this.props.active ? -8:0}

            opacity={1}
            rotate={()=> this.props.active ? -45:0}
            transition={"0.33s"}
            delay={()=> this.props.active ? "0.1s":"0"}
            backgroundcolor={this.props.backgroundcolor}
            />

        </Container>
      )
    }
}

export default Hamburger
