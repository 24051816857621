import React from 'react';
import styled from 'styled-components';
import { Flex } from 'grid-styled';

const Standard = styled( ({ padding, ...props}) => <Flex {...props} />)`

  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  display: ${({ inline }) => (inline) ? "inline" : "flex"};

  align-items: ${({ alignitems }) => (alignitems) ? alignitems : "center"};
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : "center"};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : "column"};

  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" :""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px":""};
  padding-left: ${({ padding }) => (padding) ? padding:""}px;
  padding-right: ${({ padding }) => (padding) ? padding:""}px;
  width: auto;

  color: ${({ color }) => (color) ? color : "white"} !important;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};
  text-decoration: ${({ underlineOnHover }) => (underlineOnHover) ? "underline" : ""}
`

const H1Header = styled( ({ margintop, padding, color, font, fontSize, fontWeight, textalign, alignitems, justifycontent, lineheight, children, ...props}) => <h1 {...props}>{children}</h1>)`

  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  display: flex;
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : "center"};
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : "center"};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : "column"};

  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" :""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px":""};
  padding-left: ${({ padding }) => (padding) ? padding:""}px;
  padding-right: ${({ padding }) => (padding) ? padding:""}px;
  width: auto;

  color: ${({ color }) => (color) ? color : "white"}!important;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};

  text-decoration: ${({ underlineOnHover }) => (underlineOnHover) ? "underline" : ""};
`

const H2Header = styled( ({ padding, children, ...props}) => <h2 {...props}>{children}</h2>)`

  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  display: ${({ inline }) => (inline) ? "inline" : "flex"};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : "center"};
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : "center"};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : "column"};

  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" :""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px":""};
  padding-left: ${({ padding }) => (padding) ? padding:""}px;
  padding-right: ${({ padding }) => (padding) ? padding:""}px;
  width: auto;

  color: ${({ color }) => (color) ? color : "white"}!important;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};

  text-decoration: ${({ underlineOnHover }) => (underlineOnHover) ? "underline" : ""};
`

const H3Header = styled( ({ padding, children, ...props}) => <h3 {...props}>{children}</h3>)`

  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  display: ${({ inline }) => (inline) ? "inline" : "flex"};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : "center"};
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : "center"};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : "column"};

  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" :""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px":""};
  padding-left: ${({ padding }) => (padding) ? padding:""}px;
  padding-right: ${({ padding }) => (padding) ? padding:""}px;
  width: auto;

  color: ${({ color }) => (color) ? color : "white"}!important;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};

  text-decoration: ${({ underlineOnHover }) => (underlineOnHover) ? "underline" : ""};
`

const H4Header = styled( ({ padding, children, ...props}) => <h4 {...props}>{children}</h4>)`

  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  display: ${({ inline }) => (inline) ? "inline" : "flex"};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : "center"};
  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : "center"};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : "column"};

  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" :""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px":""};
  padding-left: ${({ padding }) => (padding) ? padding:""}px;
  padding-right: ${({ padding }) => (padding) ? padding:""}px;
  width: auto;

  color: ${({ color }) => (color) ? color : "white"}!important;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};

  text-decoration: ${({ underlineOnHover }) => (underlineOnHover) ? "underline" : ""};
`


class TypographicElement extends React.Component {

  constructor(props){
    super(props);
    this.state = {

    }
    // Mounted ?
    this._isMounted = false

    // Devmode
    this.devMode = false

    this.renderStandard = this.renderStandard.bind(this)
    this.renderH1 = this.renderH1.bind(this)
    this.renderH2 = this.renderH2.bind(this)
    this.renderH3 = this.renderH3.bind(this)
    this.renderH4 = this.renderH4.bind(this)

  }

  componentDidMount() {
    // Mounted
    this._isMounted = true
  }

  componentWillUnmount() {
    // Not Mounted
    this._isMounted = false
  }


  componentDidUpdate(newProps) {
    if (newProps !== this.props) {

    }
  }

  renderH1() {
    if (this.props.dangerouslySetInnerHTML) {
      return(
        <H1Header
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          dangerouslySetInnerHTML={{ __html: this.props.children  }}
          >

        </H1Header>
      )
    } else {
      return(
        <H1Header
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          >
          {this.props.children}
        </H1Header>
      )
    }
  }

  renderH2() {
    if (this.props.dangerouslySetInnerHTML) {
      return(
        <H2Header
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          dangerouslySetInnerHTML={{ __html: this.props.children  }}
          >

        </H2Header>
      )
    } else {
      return(
        <H2Header
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          >
          {this.props.children}
        </H2Header>
      )
    }
  }

  renderH3() {
    if (this.props.dangerouslySetInnerHTML) {
      return(
        <H3Header
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          dangerouslySetInnerHTML={{ __html: this.props.children  }}
          >

        </H3Header>
      )
    } else {
      return(
        <H3Header
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          >
          {this.props.children}
        </H3Header>
      )
    }
  }

  renderH4() {
    if (this.props.dangerouslySetInnerHTML) {
      return(
        <H4Header
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          dangerouslySetInnerHTML={{ __html: this.props.children  }}
          >

        </H4Header>
      )
    } else {
      return(
        <H4Header
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          >
          {this.props.children}
        </H4Header>
      )
    }
  }

  renderStandard() {
    if (this.props.dangerouslySetInnerHTML) {
      return(
        <Standard
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          dangerouslySetInnerHTML={{ __html: this.props.children  }}
          >

        </Standard>
      )
    } else {
      return(
        <Standard
          inline={this.props.inline}
          margintop={this.props.margintop}
          font={this.props.font}
          fontSize={this.props.fontSize}
          fontWeight={this.props.fontWeight}
          padding={this.props.padding}
          textalign={this.props.textalign}
          justifycontent={this.props.justifycontent}
          alignitems={this.props.alignitems}
          color={this.props.color}
          lineheight={this.props.lineheight}
          underlineOnHover={this.props.underlineOnHover}
          >
          {this.props.children}
        </Standard>
      )
    }
  }

  render() {

    switch(this.props.type) {
      case "h1":
        return this.renderH1()
      case "h2":
        return this.renderH2()
      case "h3":
        return this.renderH3()
      case "h4":
          return this.renderH4()
      default:
        return this.renderStandard()
    }


  }
}

export default TypographicElement
