import React from 'react';
import styled from 'styled-components';
import { Flex } from 'grid-styled';

const Image = styled( ({ height, ...props}) => <Flex {...props} />)`
  display: ${({ display }) => (display) ? display : ""};
  width: ${({ width }) => (width) ? width + "px" : ""};
  height: ${({ height }) => (height) ? height + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor) ? backgroundcolor : ""};
  background-image: ${({ backgroundimage }) => (backgroundimage) ? ('url('+backgroundimage+')') : ""};
  background: ${({ background }) => (background) ? ('url('+background+')') : ""};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: ${({ backgroundSize }) => (backgroundSize)};
`

const AutoSizedImage = styled( ({ height, padding, ...props}) => <img alt="" {...props} />)`
  width: 100%;
  height: auto;
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundColor }) => (backgroundColor)};
`
export { Image, AutoSizedImage }
export default Image
