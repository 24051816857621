import React from 'react'
import { Flex } from 'grid-styled';
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import videojs from 'video.js'
import FlexContainer from '@components/common/flex-container'

import HLSPlayer from './players/react-hls-player'
import StandardPlayer from './players/standard-player'

const LiveStream = styled( ({ marginTop, height,  ...props }) => <iframe title="youtube" {...props} />)`
  margin-top: ${({ marginTop }) => (marginTop)};
  width: ${({ width }) => (width)};
  height:${({ height }) => (height)};
  border: none;
  padding-right: ${({ padding }) => (padding)};
  padding-left: ${({ padding }) => (padding)};
  object-fit:cover;
`

const M3u8 = styled( ({ marginTop, height,  ...props }) => <video {...props} />)`
  width: ${({ width }) => (width)};
  height:${({ height }) => (height)};
  object-fit:cover;
`

const vimeoURL = "https://player.vimeo.com/video/555935606"


class VideoPlayer extends React.Component {
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(this.videoNode, this.props, function onPlayerReady() {
      console.log('onPlayerReady', this)
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div>
        <div data-vjs-player>
          <video ref={ node => this.videoNode = node } className="video-js"></video>
        </div>
      </div>
    )
  }
}

class Livestream extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)

    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){
      if (newProps !== this.props) {
        // const videoplayer = window.document.getElementById("livestream")
        // console.log("videoplayer ", videoplayer);
        // videoplayer.oncanplay = () => {
        //   alert("Can start playing video");
        // }
      }
    }

    onResize(){
      // this.forceUpdate()
    }

    renderVideoJS() {
      const videoJsOptions = {
            autoplay: true,
            controls: false,
            muted: true,
            sources: [{
               src:"https://devstreaming-cdn.apple.com/videos/streaming/examples/bipbop_16x9/bipbop_16x9_variant.m3u8", type:"application/x-mpegURL"
            }]
          }

          return <VideoPlayer { ...videoJsOptions } />
    }

    renderFirstIterationLivestream() {
      return <LiveStream
      width={this.props.width}
      height={this.props.height}
      src={this.props.src}/>
    }

    onPlayerReady() {

    }

    renderStandardPlayer() {
      return (<StandardPlayer
        width={this.props.width}
        height={this.props.height}
        backgroundcolor="clear"
        />)
    }

    renderReactHLSPlayer() {
      return <HLSPlayer
        width={this.props.width}
        height={this.props.height} />
    }

    render(){

      return this.renderReactHLSPlayer()
    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    breakpoint: state.gridData.breakpoint,
    margin: state.gridData.margin,
    activePageIndex: state.activePageIndex
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Livestream);
