import React from 'react'
import styled from 'styled-components';
import { Flex } from 'grid-styled';

const LogoContainer = styled( ({ ...props}) => <Flex {...props} alt="Logo"/>)`
  float:left;
  width: auto;
  height: ${({ height }) => (height) ? height + "px" : ""};
  justify-content: left;
  align-items: center;
  z-index:100000;
`

const Logo = styled( ({float, ...props}) => <img {...props} alt="Logo"/>)`
  height:30px;
`

export default Logo
export { Logo, LogoContainer }
