import React from 'react'
import styled from 'styled-components';
import { Flex } from 'grid-styled';
import { StyledRouterLink } from '../../../../../common'

import {connect} from 'react-redux';
// import {bindActionCreators} from 'redux';

const Container = styled( ({ width, height, top, ...props }) => <Flex {...props} />)`
  position: absolute;
  top: 64px;
  left:0;
  right:0;
  bottom: 0;
  height: ${({ height }) => (height)}px;
  width: ${({ width }) => (width)}px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-color: white;
`

const ColumnContainer = styled( ({height, left,  ...props}) => <Flex {...props} />)`
  position: absolute;
  top: 0px;
  left: ${({ left }) => (left)}px;
  width: 50vw;
  height: ${({ height }) => (height)}px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LinksContentContainer = styled( ({float, width, marginLeft, marginLeftMobile, marginRight, marginRightMobile, ...props}) => <Flex {...props} />)`
  width: 50vw;
  height: 60px;
  justify-content: center;
  align-items: center;
  font-size: 28px;
  color: black;
  background-color: white;

  font-family: ${({ font }) => (font) ? font : "din-2014"};
  font-size: ${({ fontSize }) => (fontSize) ? fontSize : "10"}px;
  font-weight: ${({ fontWeight }) => (fontWeight) ? fontWeight : "300"};
  line-height: ${({ lineheight }) => (lineheight) ? lineheight : ""};
  text-align: ${({ textalign }) => (textalign) ? textalign : "center"};

  text-decoration: ${({ underline }) => (underline) ? "underline" : ""};

  :hover {
    color: ${({ hoverColor }) => (hoverColor) ? hoverColor : ""};
  }

`


class LandscapeMenu extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }

      this.renderNavLinks = this.renderNavLinks.bind(this)
      this.onNavLinkClick = this.onNavLinkClick.bind(this)
    }

    componentDidMount() {

    }

    onNavLinkClick(title) {
      this.props.refreshMenu(title)
    }

    renderNavLinks() {

      const firstHalfLinks = this.props.navigationLinks.map((item, index) => {
        if (index <= (this.props.navigationLinks.length/2-1)) {
          // <StyledRouterLink
          //   key={index}
          //   to={item["navLinkUrl"]}
          //   >
          return (
              <LinksContentContainer
              key={index}
              float="right"
              width="auto"
              marginRight="0"
              font={this.props.navItem.font}
              fontSize={this.props.navItem.fontSize}
              fontWeight={this.props.navItem.fontWeight}
              lineheight={this.props.navItem.lineheight}
              onClick={() => this.onNavLinkClick(item["navLinkUrl"])}
              underline={item["navLinkTitle"] === this.props.activeRoute}
              hoverColor={this.props.colors.red}
              >
              {item["navLinkTitle"]}
            </ LinksContentContainer>

        )
        // </StyledRouterLink>
        }
        else {
          return(<div key={index}></div>)
        }
      })

      const secondHalfLinks = this.props.navigationLinks.map((item, index) => {
        if (index > (this.props.navigationLinks.length/2-1)) {
          // <StyledRouterLink
          //   key={index}
          //   to={item["navLinkUrl"]}
          //   >
          return (
              <LinksContentContainer
              key={index}
              float="right"
              width="auto"
              marginRight="0"
              font={this.props.navItem.font}
              fontSize={this.props.navItem.fontSize}
              fontWeight={this.props.navItem.fontWeight}
              lineheight={this.props.navItem.lineheight}
              onClick={() => this.onNavLinkClick(item["navLinkUrl"])}
              underline={item["navLinkTitle"] === this.props.activeRoute}
              hoverColor={this.props.colors.red}
              >
              {item["navLinkTitle"]}
            </ LinksContentContainer>
        )
        // </StyledRouterLink>
        }
        else {
          return(<div key={index}></div>)
        }
      })

      return(
        <div>
          <ColumnContainer
            height={window.innerHeight-40}
            left={0}
            >
            {firstHalfLinks}
          </ColumnContainer>
          <ColumnContainer
            height={window.innerHeight-40}
            left={window.innerWidth/2}
            >
            {secondHalfLinks}
          </ColumnContainer>
        </div>
      )
    }

    render(){
      if (this.props.navItem === undefined) {
        return <div/>
      }
      return(
        <Container
          width={window.innerWidth}
          height={window.innerHeight-40}
          top={64}
        >
          {this.renderNavLinks()}
        </Container>
      )
    }
}

function mapStateToProps(state){
  return{
    activeRoute: state.activeRoute,
    colors: state.styleguide.colors,
    navItem: state.styleguide.navItem,
    scrollEnabled: state.scrollEnabled

  }
}
export default connect(mapStateToProps, null)(LandscapeMenu);
