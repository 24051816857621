import React from 'react'
import FlexContainer from '../common/flex-container'
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import Locked from './modes/locked'
import Unlocked from './modes/unlocked'

class NavigationBar extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }

      // this.renderNavigationBar = this.renderNavigationBar.bind(this)

      this.navigationLinks = [
        // {
        //   "navLinkTitle"  : "about",
        //   "navLinkUrl"    : "/about"
        // },
        // {
        //   "navLinkTitle"  : "services",
        //   "navLinkUrl"    : "/services"
        // },
        // {
        //   "navLinkTitle"  : "work",
        //   "navLinkUrl"    : "/"
        // },
        // {
        //   "navLinkTitle"  : "clients",
        //   "navLinkUrl"    : "/clients"
        // },
        // {
        //   "navLinkTitle"  : "blog",
        //   "navLinkUrl"    : "/blog"
        // },
        // {
        //   "navLinkTitle"  : "careers",
        //   "navLinkUrl"    : "/careers"
        // },
        // {
        //   "navLinkTitle"  : "contact",
        //   "navLinkUrl"    : "/contact"
        // }
      ]

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)
    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){
      
    }

    onResize(){
      // this.forceUpdate()
    }

    renderNavBar() {
      if (this.props.approvedForAccess) {
        return <Unlocked/>
      } else {
        return <Locked/>
      }
    }

    render(){
      if (this.props.margin === undefined) {
        return <div/>
      }
      return(
        <div>
          {this.renderNavBar()}
        </div>
      )

    }
}

function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    approvedForAccess: state.approvedForAccess,
    activeRoute: state.activeRoute,
    margin: state.gridData.margin,
    maxWidth: state.gridData.maxWidth
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);
