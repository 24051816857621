const baseDesktopURL = "/_media/prototype/cards/desktop/"
const baseMobileURL = "/_media/prototype/cards/mobile/"
// Cards
const card1 = {
  type: "",
  mobileNavigation: {
    logo: "white",
    underlinecolor: "white",
    paginationActiveColor: "white",
    paginationInactiveColor: "paginationOffWhite"
  },
  image: {
    desktop: baseDesktopURL+"0-welcome.webp",
    mobile: baseMobileURL+"0-welcome.webp"
  }
}

const card2 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "white",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffWhite"
  },
  image: {
    desktop: baseDesktopURL+"1-howwework.webp",
    mobile: baseMobileURL+"1-howwework.webp"
  }
}

const card3 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "black",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffBlack"
  },
  image: {
    desktop: baseDesktopURL+"2-voices.webp",
    mobile: baseMobileURL+"2-voices.webp"
  }

}

const card4 = {
  type: "featured event",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "white",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffWhite"
  },
  image: {
    desktop: baseDesktopURL+"3-featuredevent.webp",
    mobile: baseMobileURL+"3-featuredevent.webp"
  },
  story: baseMobileURL+"3-featureeevent-story.png",

}

const card5 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "white",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffWhite"
  },
  image: {
    desktop: baseDesktopURL+"4-keyresources.webp",
    mobile: baseMobileURL+"4-keyresources.webp"
  }
}

const card6 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "white",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffWhite"
  },
  image: {
    desktop: baseDesktopURL+"5-feature.webp",
    mobile: baseMobileURL+"5-feature.webp"
  }
}

const card7 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "black",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffBlack"
  },
  image: {
    desktop: baseDesktopURL+"6-feature.webp",
    mobile: baseMobileURL+"6-feature.webp"
  }
}

const card8 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "black",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffWhite"
  },
  image: {
    desktop: baseDesktopURL+"7-feature.webp",
    mobile: baseMobileURL+"7-feature.webp"
  }
}

const card9 = {
  type: "",
  mobileNavigation:{
    logo: "red",
    underlinecolor: "black",
    paginationActiveColor: "red",
    paginationInactiveColor: "paginationOffBlack"
  },
  image: {
    desktop: baseDesktopURL+"8-feature.webp",
    mobile: baseMobileURL+"8-feature.webp"
  }
}

export default [
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9
]
