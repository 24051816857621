import React from 'react'
import styled from 'styled-components';
import { Link } from 'gatsby';
import Hamburger from './hamburger';

const StyledRouterLink = styled(({ light, hoverColor, ...rest }) => (<Link {...rest} />))`
  text-decoration:none;
  color: white;
`;

export default StyledRouterLink
export { StyledRouterLink, Hamburger }
