export default function(state=false, action) {
  switch(action.type){
    case "UPDATE_SCREEN_GRID_DATA":
      return action.payload;

    default:
      break;
  }
  return state;
}
