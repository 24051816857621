export default [
  {
      margin: 30,
      minimumWidth: 0,
      maximumWidth: 599
  },
  {
      margin: 30,
      minimumWidth: 600,
      maximumWidth: 959
  },
  {
      margin: 30,
      minimumWidth: 960,
      maximumWidth: 100000000000
  }
]
