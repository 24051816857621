import React from 'react';
import styled from 'styled-components';
import { Flex } from 'grid-styled';

const FlexContainer = styled(({ ...props}) => <Flex {...props} />)`
  cursor: ${({ cursor }) => (cursor)};
  pointer-events: ${({ pointer }) => (pointer) ? pointer : ""};

  overflow: ${({ overflow }) => (overflow)};
  overflow-x: ${({ overflowx }) => (overflowx)};
  overflow-y: ${({ overflowy }) => (overflowy)};

  position: ${({ position }) => (position) ? position : ""};
  z-index: ${({ zindex }) => (zindex) ? zindex : ""};

  top: ${({ top }) => (top) ? top : ""};
  left: ${({ left }) => (left) ? left : ""};
  right: ${({ right }) => (right) ? right : ""};
  bottom: ${({ bottom }) => (bottom) ? bottom : ""};

  min-width: ${({ minwidth }) => (minwidth) ? minwidth : ""};
  min-height: ${({ minheight }) => (minheight) ? minheight : ""};
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};

  justify-content: ${({ justifycontent }) => (justifycontent) ? justifycontent : ""};
  align-items: ${({ alignitems }) => (alignitems) ? alignitems : ""};
  flex-direction: ${({ flexdirection }) => (flexdirection) ? flexdirection : ""};

  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  margin-left: ${({ marginleft }) => (marginleft) ? marginleft + "px" : ""};
  margin-right: ${({ marginright }) => (marginright) ? marginright + "px" : ""};

  padding-top: ${({ paddingtop }) => (paddingtop) ? paddingtop + "px" : ""};
  padding-left: ${({ padding }) => (padding) ? padding + "px" : ""};
  padding-right: ${({ padding }) => (padding) ? padding + "px" : ""};

  background-color: ${({ backgroundcolor }) => (backgroundcolor)};

  border: ${({ border }) => (border)};
  border-radius: ${({ borderradius }) => (borderradius)};

  border-bottom: ${({ borderbottom }) => (borderbottom) ? borderbottom : ""};

  box-shadow: ${({ boxshadow }) => (boxshadow) ? boxshadow : ""};

  scroll-snap-align: ${({ scrollsnapalign }) => (scrollsnapalign) ? scrollsnapalign : ""};
  scroll-snap-type: ${({ scrollsnaptype }) => (scrollsnaptype) ? scrollsnaptype : ""};

  transform: ${({ transform }) => (transform) ? transform : ""};
  transition: ${({ transition }) => (transition) ? transition : ""};

  /* Enable Safari touch scrolling physics which is needed for scroll snap */
  -webkit-overflow-scrolling: touch;

  background-image: ${({ backgroundimage }) => (backgroundimage) ? ('url('+backgroundimage+')') : ""};
  background-size: ${({ backgroundSize }) => (backgroundSize)};

`

export default FlexContainer
