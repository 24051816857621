import React from 'react'
import { Flex } from 'grid-styled';
import styled from 'styled-components';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import FlexContainer from '@components/common/flex-container'
import SampleCards from '@data/samplecards'

const Image = styled( ({ height, padding, ...props}) => <img alt="" {...props} />)`
  width: ${({ width }) => (width) ? width : ""};
  height: ${({ height }) => (height) ? height : ""};
  margin-top: ${({ margintop }) => (margintop) ? margintop + "px" : ""};
  margin-bottom: ${({ marginbottom }) => (marginbottom) ? marginbottom + "px" : ""};
  padding: ${({ padding }) => (padding) ? padding + "px" : ""};
  background-color: ${({ backgroundcolor }) => (backgroundcolor)};
`

class Pagination extends React.Component {

    constructor(props){
      super(props);
      this.state = {

      }

      // Update Properties on resize
      this.onResize = this.onResize.bind(this)
    }

    componentDidMount() {
      // Resize evetn listner
      // window.addEventListener("resize", this.onResize)
    }

    componentWillUnmount() {
      // Resize evetn listner
      // window.removeEventListener("resize", this.onResize)
    }

    componentDidUpdate(newProps){

    }

    onResize(){
      // this.forceUpdate()
    }

    getColorFromStyleguide(name) {
      switch(name) {
        case "red":
          return this.props.colors.red
        case "white":
          return this.props.colors.white
        case "paginationOffBlack":
          return this.props.colors.paginationOffBlack
        case "paginationOffWhite":
          return this.props.colors.paginationOffWhite
      }
    }

    getMobileActiveColor() {
      const activeItem = SampleCards[this.props.activePageIndex].mobileNavigation
      return this.getColorFromStyleguide(activeItem.paginationActiveColor)
    }

    getMobileInactiveColor() {
      const activeItem = SampleCards[this.props.activePageIndex].mobileNavigation
      return this.getColorFromStyleguide(activeItem.paginationInactiveColor)
    }

    renderPagination() {
      const count = SampleCards.length
      var pages = []

      const size = 8

      var backgroundcolor



      for (var i=0; i<count; i++) {
        if (this.props.breakpoint <= 1) {
          backgroundcolor = this.props.activePageIndex === i ? this.getMobileActiveColor() : this.getMobileInactiveColor()

        } else {
          backgroundcolor = this.props.activePageIndex === i ? this.props.colors.red : this.props.colors.paginationOffBlack
        }

        pages.push(
          <FlexContainer
            key={"pagation-index-"+i}
            width={size+"px"}
            height={size+"px"}
            borderradius={size/2+"px"}
            backgroundcolor={ backgroundcolor }
            marginleft={i === 0 ? 0 : size}
            transition="0.67s"
          />
        )
      }

      return pages
    }

    render(){
      if (this.props.colors === undefined) {
        return <div/>
      }
      return(
        <FlexContainer
          position="absolute"
          top="0"
          left="0"
          right="0"
          height={this.props.height}
          zindex="1"
          backgroundcolor={"clear"}
          justifycontent="center"
          alignitems="center"
        >
          { this.renderPagination() }
        </FlexContainer>
      )

    }
}


function mapDispatchToProps(dispatch){
  return bindActionCreators({

  }, dispatch);
}

function mapStateToProps(state){
  return{
    margin: state.gridData.margin,
    colors: state.styleguide.colors,
    activePageIndex: state.activePageIndex,
    breakpoint: state.gridData.breakpoint
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
